import { CommonModule, DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Ic2Module } from '@ic2/ic2-lib';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BaseChartDirective, provideCharts, withDefaultRegisterables } from 'ng2-charts';
import common_locale_de from '../locale/de.json';
import common_locale_en from '../locale/en.json';
import common_locale_fr from '../locale/fr.json';
import common_locale_nl from '../locale/nl.json';
import { AutologComponent } from './components/autolog/autolog.component';
import { DownloadGraphComponent } from './components/charts/download-graph/download-graph.component';
import { RadarChartComponent } from './components/charts/radar-chart/radar-chart.component';
import { StatsOgChartCriteria245Component } from './components/charts/stats-og-chart-criteria245/stats-og-chart-criteria245.component';
import { StatsOgChartNpsComponent } from './components/charts/stats-og-chart-nps/stats-og-chart-nps.component';
import { TagRadarChartComponent } from './components/charts/tag-radar-chart/tag-radar-chart.component';
import { CreatedUpdatedByComponent } from './components/created-updated-by/created-updated-by.component';
import { DataProcessingModalComponent } from './components/data-processing-modal/data-processing-modal.component';
import { DatetimePickerComponent } from './components/datetime-picker/datetime-picker.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FormationQuizSendEmailModalComponent } from './components/formation-quiz-send-email-modal/formation-quiz-send-email-modal.component';
import { Ic2ToastrContainerComponent } from './components/ic2-toastr-container/ic2-toastr-container.component';
import { InDevelopmentComponent } from './components/in-development/in-development.component';
import { LoginSSOComponent } from './components/login-sso/login-sso.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { MailAuthComponent } from './components/mail-auth/mail-auth.component';
import { ModalComponent } from './components/modal/modal.component';
import { NotFoundComponent } from './components/notfound/notfound.component';
import { OrganisationIconComponent } from './components/organisation-icon/organisation-icon.component';
import { QuizQcmDetailsModalComponent } from './components/quiz-qcm-details-modal/quiz-qcm-details-modal.component';
import { RepositorySelectComponent } from './components/repository-select/repository-select.component';
import { ResetPasswordFormComponent } from './components/reset-password/reset-password-form/reset-password-form.component';
import { ResetPasswordMailSentComponent } from './components/reset-password/reset-password-mail-sent/reset-password-mail-sent.component';
import { ResetPasswordPincodeComponent } from './components/reset-password/reset-password-pincode/reset-password-pincode.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password/reset-password.component';
import { ScoreComponent } from './components/score/score.component';
import { SignupComponent } from './components/signup/signup.component';
import { SmartPaginationComponent } from './components/smart-pagination/smart-pagination.component';
import { SsoErrorComponent } from './components/sso-error/sso-error.component';
import { StatCardComponent } from './components/stat-card/stat-card.component';
import { StaticPageDisplayComponent } from './components/static-page-display/static-page-display.component';
import { HijiSwitchComponent } from './components/switch/hiji-switch.component';
import { TagDisplayComponent } from './components/tag-display/tag-display.component';
import { TomAuthComponent } from './components/tom-auth/tom-auth.component';
import { TranslateTextsComponent } from './components/translate-texts/translate-texts.component';
import { UpdateAvailableComponent } from './components/update-available/update-available.component';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { WavyContainerComponent } from './components/wavy-container/wavy-container.component';
import { LanguageInterceptor } from './services/language-http-interceptor.service';
import { BackDirective } from './tools/back.directive';
import { LocalizedDatePipe } from './tools/localized-date.pipe';
import { ScrollTrackerDirective } from './tools/scroll-tracker.directive';
import { TruncatePipe } from './tools/truncate.pipe';

@NgModule({
  declarations: [
    LoginComponent,
    LoginSSOComponent,
    SignupComponent,
    AutologComponent,
    HijiSwitchComponent,
    ResetPasswordMailSentComponent,
    ResetPasswordFormComponent,
    ResetPasswordComponent,
    ResetPasswordPincodeComponent,
    WavyContainerComponent,
    ModalComponent,
    UpdateAvailableComponent,
    NotFoundComponent,
    LogoutComponent,
    SsoErrorComponent,
    Ic2ToastrContainerComponent,
    LocalizedDatePipe,
    RadarChartComponent,
    UserAvatarComponent,
    ScrollTrackerDirective,
    DataProcessingModalComponent,
    MailAuthComponent,
    TruncatePipe,
    TagRadarChartComponent,
    TagDisplayComponent,
    DownloadGraphComponent,
    OrganisationIconComponent,
    InDevelopmentComponent,
    DatetimePickerComponent,
    BackDirective,
    StaticPageDisplayComponent,
    ScoreComponent,
    RepositorySelectComponent,
    StatsOgChartCriteria245Component,
    StatsOgChartNpsComponent,
    TranslateTextsComponent,
    TomAuthComponent,
    FileUploadComponent,
    StatCardComponent,
    QuizQcmDetailsModalComponent,
    CreatedUpdatedByComponent,
    SmartPaginationComponent,
    FormationQuizSendEmailModalComponent,
  ],
  imports: [
    Ic2Module,
    ReactiveFormsModule,
    NgbModule,
    FormsModule,
    NgbModule,
    BaseChartDirective,
    CommonModule,
    RouterModule,
    TranslateModule.forChild({ extend: true }),
  ],
  exports: [
    FileUploadComponent,
    LoginComponent,
    ScoreComponent,
    LoginSSOComponent,
    SignupComponent,
    AutologComponent,
    HijiSwitchComponent,
    ResetPasswordMailSentComponent,
    ResetPasswordFormComponent,
    ResetPasswordComponent,
    ResetPasswordPincodeComponent,
    WavyContainerComponent,
    ModalComponent,
    UpdateAvailableComponent,
    NotFoundComponent,
    LogoutComponent,
    SsoErrorComponent,
    Ic2ToastrContainerComponent,
    StatsOgChartCriteria245Component,
    StatsOgChartNpsComponent,
    LocalizedDatePipe,
    RadarChartComponent,
    UserAvatarComponent,
    ScrollTrackerDirective,
    TruncatePipe,
    TagRadarChartComponent,
    TagDisplayComponent,
    DownloadGraphComponent,
    OrganisationIconComponent,
    InDevelopmentComponent,
    DatetimePickerComponent,
    BackDirective,
    StaticPageDisplayComponent,
    RepositorySelectComponent,
    TomAuthComponent,
    StatCardComponent,
    QuizQcmDetailsModalComponent,
    CreatedUpdatedByComponent,
    SmartPaginationComponent,
    FormationQuizSendEmailModalComponent,
  ],
  providers: [
    LocalizedDatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true, deps: [Injector] },
    DecimalPipe,
    provideCharts(withDefaultRegisterables()),
  ],
})
export class HijiCommonModule {
  constructor(translate: TranslateService) {
    translate.setTranslation('fr', common_locale_fr, true);
    translate.setTranslation('en', common_locale_en, true);
    translate.setTranslation('nl', common_locale_nl, true);
    translate.setTranslation('de', common_locale_de, true);
  }
}
