// fr.hiji.metier.fbs.services.FeedbackServiceI generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:49 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { FeedbackRecipientType } from '../entities/FeedbackRecipientType';
import { FeedbackCategory } from '../entities/FeedbackCategory';
import { FeedbackSolicitationDTO } from '../entities/FeedbackSolicitationDTO';
import { FeedbackReaction } from '../entities/FeedbackReaction';
import { FeedbackDTO } from '../entities/FeedbackDTO';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getFeedbackCategories(): RpcRequestBuilder<FeedbackCategory[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FeedbackService.getFeedbackCategories';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getFeedbacks(offset: number): RpcRequestBuilder<FeedbackDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FeedbackService.getFeedbacks';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(offset, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  react(idFeedback: number, reaction: FeedbackReaction): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FeedbackService.react';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFeedback, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(reaction, 'fr.hiji.metier.fbs.entities.FeedbackReaction', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  askForFeedback(idUser: number, subject: string, description: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FeedbackService.askForFeedback';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(subject, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(description, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  sendFeedback(recipientType: FeedbackRecipientType, groupName: string, idFeedbackCategory: number, message: string, users: number[], idFeedbackSolicitation: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FeedbackService.sendFeedback';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(recipientType, 'fr.hiji.metier.fbs.entities.FeedbackRecipientType', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(groupName, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFeedbackCategory, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(message, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(users, 'java.util.List<java.lang.Integer>', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFeedbackSolicitation, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getSolicitationInfos(idSolicitation: number): RpcRequestBuilder<FeedbackSolicitationDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FeedbackService.getSolicitationInfos';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idSolicitation, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.home.dto.front.FeedbackSolicitationDTO');
  }

}
