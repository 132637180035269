// ENTITY fr.hiji.metier.user.dto.back.UserRelationBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:59 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { UserRelation } from './UserRelation';

export class UserRelationBODTO {

  userRelation: UserRelation | null = null;
  firstNameUserLie: string | null = '';
  lastNameUserLie: string | null = '';
  emailUserLie: string | null = '';
  fonction: string | null = '';
  etablissements: string[] | null = [];
  userDeleted: boolean | null = false;
  added: boolean | null = false;
  deleted: boolean | null = false;

  constructor(init?:Partial<UserRelationBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.user.dto.back.UserRelationBODTO'] = UserRelationBODTO;

reverseMapping[UserRelationBODTO.name] = 'fr.hiji.metier.user.dto.back.UserRelationBODTO';

fields['fr.hiji.metier.user.dto.back.UserRelationBODTO']  = {
    userRelation: 'com.ic2.entity.UserRelation',
    firstNameUserLie: 'java.lang.String',
    lastNameUserLie: 'java.lang.String',
    emailUserLie: 'java.lang.String',
    fonction: 'java.lang.String',
    etablissements: 'java.util.List<java.lang.String>',
    userDeleted: 'java.lang.Boolean',
    added: 'java.lang.Boolean',
    deleted: 'java.lang.Boolean'
};
