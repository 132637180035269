// ENTITY fr.hiji.metier.campaign.entities._360.InquiryTarget generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { InquiryTargetType } from './InquiryTargetType';

export class InquiryTarget {

  idInquiry: number | null = 0;
  idUser: number | null = 0;
  type: InquiryTargetType | null = null;
  deployed: boolean | null = false;
  deployDate: Date | null = null;
  sendMail: boolean | null = false;

  constructor(init?:Partial<InquiryTarget>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.entities._360.InquiryTarget'] = InquiryTarget;

reverseMapping[InquiryTarget.name] = 'fr.hiji.metier.campaign.entities._360.InquiryTarget';

fields['fr.hiji.metier.campaign.entities._360.InquiryTarget']  = {
    idInquiry: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    type: 'fr.hiji.metier.campaign.entities._360.InquiryTargetType',
    deployed: 'java.lang.Boolean',
    deployDate: 'java.time.Instant',
    sendMail: 'java.lang.Boolean'
};
