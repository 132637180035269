// ENTITY fr.hiji.metier.mif.dto.front.trainer.LayoutTrainerDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:59 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class LayoutTrainerDTO {

  idFormation: number | null = 0;
  formationName: MultilingualString | null = new MultilingualString();
  formationColorCode: string | null = '';
  formationSessionName: string | null = '';
  formationImageExtension: string | null = '';
  minDate: Date | null = null;
  maxDate: Date | null = null;
  formationSessionNbParticipantSlots: number | null = 0;
  formationSessionColorCode: string | null = '';

  constructor(init?:Partial<LayoutTrainerDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.trainer.LayoutTrainerDTO'] = LayoutTrainerDTO;

reverseMapping[LayoutTrainerDTO.name] = 'fr.hiji.metier.mif.dto.front.trainer.LayoutTrainerDTO';

fields['fr.hiji.metier.mif.dto.front.trainer.LayoutTrainerDTO']  = {
    idFormation: 'java.lang.Integer',
    formationName: 'com.ic2.sc.MultilingualString',
    formationColorCode: 'java.lang.String',
    formationSessionName: 'java.lang.String',
    formationImageExtension: 'java.lang.String',
    minDate: 'java.time.LocalDate',
    maxDate: 'java.time.LocalDate',
    formationSessionNbParticipantSlots: 'java.lang.Integer',
    formationSessionColorCode: 'java.lang.String'
};
