// ENTITY fr.hiji.metier.campaign.dto.front.pdi.PDIObsDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:59 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { ObservationType } from './ObservationType';

export class PDIObsDTO {

  idObservation: number | null = 0;
  observer: string | null = '';
  type: ObservationType | null = null;

  constructor(init?:Partial<PDIObsDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.front.pdi.PDIObsDTO'] = PDIObsDTO;

reverseMapping[PDIObsDTO.name] = 'fr.hiji.metier.campaign.dto.front.pdi.PDIObsDTO';

fields['fr.hiji.metier.campaign.dto.front.pdi.PDIObsDTO']  = {
    idObservation: 'java.lang.Integer',
    observer: 'java.lang.String',
    type: 'fr.hiji.metier.campaign.entities.ObservationType'
};
