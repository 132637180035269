// ENTITY fr.hiji.metier.certif.dto.back.modality.interview.ModalityInterviewBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { ModalityInterviewTarget } from './ModalityInterviewTarget';
import { ModalityInterviewOGPerTag } from './ModalityInterviewOGPerTag';
import { ModalityInterview } from './ModalityInterview';

export class ModalityInterviewBODTO {

  modalityInterview: ModalityInterview | null = null;
  targets: ModalityInterviewTarget[] | null = [];
  questionsPerTags: ModalityInterviewOGPerTag[] | null = [];
  repositoryName: MultilingualString | null = new MultilingualString();
  repositoryDel: boolean | null = false;

  constructor(init?:Partial<ModalityInterviewBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.back.modality.interview.ModalityInterviewBODTO'] = ModalityInterviewBODTO;

reverseMapping[ModalityInterviewBODTO.name] = 'fr.hiji.metier.certif.dto.back.modality.interview.ModalityInterviewBODTO';

fields['fr.hiji.metier.certif.dto.back.modality.interview.ModalityInterviewBODTO']  = {
    modalityInterview: 'fr.hiji.metier.certif.entities.modality.interview.ModalityInterview',
    targets: 'java.util.List<fr.hiji.metier.certif.entities.modality.interview.ModalityInterviewTarget>',
    questionsPerTags: 'java.util.List<fr.hiji.metier.certif.entities.modality.interview.ModalityInterviewOGPerTag>',
    repositoryName: 'com.ic2.sc.MultilingualString',
    repositoryDel: 'java.lang.Boolean'
};
