// ENTITY fr.hiji.metier.mif.dto.back.formationsession.FormationSessionParticipantBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { Registration } from './Registration';
import { FormationSessionParticipant } from './FormationSessionParticipant';

export class FormationSessionParticipantBODTO {

  formationSessionParticipant: FormationSessionParticipant | null = null;
  registration: Registration | null = null;
  firstName: string | null = '';
  lastName: string | null = '';
  acquisitionBeforeAnswered: boolean | null = false;
  acquisitionAfterAnswered: boolean | null = false;
  satisfactionAnswered: boolean | null = false;
  avgSatisfaction: number | null = null;
  avgAcquisitionBefore: number | null = null;
  avgAcquisitionAfter: number | null = null;
  userDel: boolean | null = false;
  added: boolean | null = false;
  deleted: boolean | null = false;
  updated: boolean | null = false;
  formationDoneByUser: boolean | null = false;
  nbAttendance: number | null = 0;

  constructor(init?:Partial<FormationSessionParticipantBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.formationsession.FormationSessionParticipantBODTO'] = FormationSessionParticipantBODTO;

reverseMapping[FormationSessionParticipantBODTO.name] = 'fr.hiji.metier.mif.dto.back.formationsession.FormationSessionParticipantBODTO';

fields['fr.hiji.metier.mif.dto.back.formationsession.FormationSessionParticipantBODTO']  = {
    formationSessionParticipant: 'fr.hiji.metier.mif.entities.session.FormationSessionParticipant',
    registration: 'fr.hiji.metier.mif.entities.registration.Registration',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    acquisitionBeforeAnswered: 'java.lang.Boolean',
    acquisitionAfterAnswered: 'java.lang.Boolean',
    satisfactionAnswered: 'java.lang.Boolean',
    avgSatisfaction: 'java.lang.Double',
    avgAcquisitionBefore: 'java.lang.Double',
    avgAcquisitionAfter: 'java.lang.Double',
    userDel: 'java.lang.Boolean',
    added: 'java.lang.Boolean',
    deleted: 'java.lang.Boolean',
    updated: 'java.lang.Boolean',
    formationDoneByUser: 'java.lang.Boolean',
    nbAttendance: 'java.lang.Integer'
};
