// DB ENUM fr.hiji.metier.ema.entities.EMAOGAddReason generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:59 UTC 2024

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class EMAOGAddReason {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly KEY_GESTURE = new EMAOGAddReason(1, "Geste clé", "KEY_GESTURE");
  static readonly CHOOSEN_LAST_EMA = new EMAOGAddReason(3, "Choisi lors du dernier entretien", "CHOOSEN_LAST_EMA");
  static readonly MASTERED_OG_TO_RECONFIRM = new EMAOGAddReason(4, "Geste maitrisé à confirmer", "MASTERED_OG_TO_RECONFIRM");
  static readonly WORK_REPOSITORY = new EMAOGAddReason(5, "Référentiel métier", "WORK_REPOSITORY");

  static readonly values = [
    EMAOGAddReason.KEY_GESTURE,
 
    EMAOGAddReason.CHOOSEN_LAST_EMA,
 
    EMAOGAddReason.MASTERED_OG_TO_RECONFIRM,
 
    EMAOGAddReason.WORK_REPOSITORY
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of EMAOGAddReason.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of EMAOGAddReason.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.ema.entities.EMAOGAddReason'] = EMAOGAddReason;
reverseMapping[EMAOGAddReason.name] = 'fr.hiji.metier.ema.entities.EMAOGAddReason';
