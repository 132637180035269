// ENTITY fr.hiji.metier.ema.dto.back.EMAObservableGestureBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class EMAObservableGestureBODTO {

  idObservableGesture: number | null = 0;
  observableGestureName: MultilingualString | null = new MultilingualString();
  benchmarkCode: string | null = '';
  idCriteria: number | null = 0;
  observableGestureDel: boolean | null = false;
  idsTags: number[] | null = [];

  constructor(init?:Partial<EMAObservableGestureBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.back.EMAObservableGestureBODTO'] = EMAObservableGestureBODTO;

reverseMapping[EMAObservableGestureBODTO.name] = 'fr.hiji.metier.ema.dto.back.EMAObservableGestureBODTO';

fields['fr.hiji.metier.ema.dto.back.EMAObservableGestureBODTO']  = {
    idObservableGesture: 'java.lang.Integer',
    observableGestureName: 'com.ic2.sc.MultilingualString',
    benchmarkCode: 'java.lang.String',
    idCriteria: 'java.lang.Integer',
    observableGestureDel: 'java.lang.Boolean',
    idsTags: 'java.util.List<java.lang.Integer>'
};
