// ENTITY fr.hiji.metier.mif.dto.back.event.stats.FormationEventStatsInfoBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:59 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class FormationEventStatsInfoBODTO {

  nbParticipant: number | null = 0;
  nbAnimation: number | null = 0;
  nbTrainer: number | null = 0;

  constructor(init?:Partial<FormationEventStatsInfoBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.event.stats.FormationEventStatsInfoBODTO'] = FormationEventStatsInfoBODTO;

reverseMapping[FormationEventStatsInfoBODTO.name] = 'fr.hiji.metier.mif.dto.back.event.stats.FormationEventStatsInfoBODTO';

fields['fr.hiji.metier.mif.dto.back.event.stats.FormationEventStatsInfoBODTO']  = {
    nbParticipant: 'java.lang.Integer',
    nbAnimation: 'java.lang.Integer',
    nbTrainer: 'java.lang.Integer'
};
