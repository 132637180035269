// ENTITY fr.hiji.metier.ema.dto.back.stats.EMACustomOGSkillStatBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class EMACustomOGSkillStatBODTO {

  idTag: number | null = 0;
  tagName: MultilingualString | null = new MultilingualString();
  colorCode: string | null = '';
  nbGesteCustomCreated: number | null = 0;
  nbGesteCustomActive: number | null = 0;

  constructor(init?:Partial<EMACustomOGSkillStatBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.back.stats.EMACustomOGSkillStatBODTO'] = EMACustomOGSkillStatBODTO;

reverseMapping[EMACustomOGSkillStatBODTO.name] = 'fr.hiji.metier.ema.dto.back.stats.EMACustomOGSkillStatBODTO';

fields['fr.hiji.metier.ema.dto.back.stats.EMACustomOGSkillStatBODTO']  = {
    idTag: 'java.lang.Integer',
    tagName: 'com.ic2.sc.MultilingualString',
    colorCode: 'java.lang.String',
    nbGesteCustomCreated: 'java.lang.Integer',
    nbGesteCustomActive: 'java.lang.Integer'
};
