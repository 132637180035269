// Facade exporting all classes for simple loading
// use export * from "./services/services"; in your module file
export * from './ActionsModuleCfgBOService';
export * from './AttendanceService';
export * from './AutoLogService';
export * from './BackConfigBOService';
export * from './BadgeService';
export * from './BilanBOService';
export * from './BilanModuleCfgBOService';
export * from './C180ModuleCfgBOService';
export * from './C360ModuleCfgBOService';
export * from './CSSConfigBOService';
export * from './CampaignBOService';
export * from './CampaignEmailBOService';
export * from './CampaignPDIBOService';
export * from './CampaignStatsBOService';
export * from './CertificationBOService';
export * from './CertificationModuleCfgBOService';
export * from './CertificationService';
export * from './CertificationSessionBOService';
export * from './CertificationStatsBOService';
export * from './CertificationStatsFilterBOService';
export * from './ContactService';
export * from './CoreService';
export * from './CriteriaBOService';
export * from './CriteriaService';
export * from './DashboardBOService';
export * from './DiffusionListBOService';
export * from './EMAListService';
export * from './EMAModuleCfgBOService';
export * from './EMAOGHistoService';
export * from './EMAObjectiveResultBOService';
export * from './EMAObservationService';
export * from './EMAService';
export * from './EMAStatsBOService';
export * from './EMATemplateBOService';
export * from './EVAQuizService';
export * from './EVAService';
export * from './EdenRedSurveySpecificService';
export * from './EmailBOService';
export * from './EventModuleCfgBOService';
export * from './ExchangeService';
export * from './FBSModuleCfgBOService';
export * from './FeedbackCategoryBOService';
export * from './FeedbackService';
export * from './FeedbackStatBOService';
export * from './FonctionBOService';
export * from './FonctionService';
export * from './FormationBOService';
export * from './FormationEmailBOService';
export * from './FormationEventAcquisitionService';
export * from './FormationEventBOService';
export * from './FormationEventCodeLoginService';
export * from './FormationEventSatisfactionService';
export * from './FormationEventService';
export * from './FormationEventStatsBOService';
export * from './FormationEventStatsFilterBOService';
export * from './FormationImportBOService';
export * from './FormationQuizBOService';
export * from './FormationQuizService';
export * from './FormationReportBOService';
export * from './FormationService';
export * from './FormationSessionActionsService';
export * from './FormationSessionBOService';
export * from './FormationSessionEmailService';
export * from './FormationSessionExpectationsService';
export * from './FormationSessionManagerService';
export * from './FormationSessionParticipantService';
export * from './FormationSessionSatisfactionService';
export * from './FormationSessionTrainerService';
export * from './FormationStatsBOService';
export * from './FormationStatsFilterBOService';
export * from './FrontConfigBOService';
export * from './GroupService';
export * from './HHEGImport360BOService';
export * from './HijiFileService';
export * from './HomeService';
export * from './ImportExportQuizBOService';
export * from './ImportExportRepositoryBOService';
export * from './ImportExportTranslationBOService';
export * from './ImportUserBOService';
export * from './ImportUserChanelBOService';
export * from './InquiryBOService';
export * from './InquiryService';
export * from './InterviewModalityService';
export * from './JoinViaCodeService';
export * from './KPIModuleCfgBOService';
export * from './MIFModuleCfgBOService';
export * from './ManifestConfigBOService';
export * from './MeltingSpotJWTService';
export * from './ModalityBOService';
export * from './ModalityService';
export * from './ModulesBOService';
export * from './ObservableGestureBOService';
export * from './ObservationService';
export * from './OpinionBOService';
export * from './OpinionService';
export * from './OrganisationBOService';
export * from './PDIModuleCfgBOService';
export * from './PDIService';
export * from './PdfService';
export * from './PopulationBOService';
export * from './ProfileService';
export * from './QuizBOService';
export * from './QuizModalityService';
export * from './RefreshSessionService';
export * from './RegisterService';
export * from './Report180ConfigBOService';
export * from './ReportDownloadService';
export * from './ReportEMAConfigBOService';
export * from './RepositoryBOService';
export * from './ResetPwdService';
export * from './RoleplayModalityService';
export * from './SSOService';
export * from './StaticPageBOService';
export * from './StaticPageService';
export * from './SurveyBOService';
export * from './SurveyModuleCfgBOService';
export * from './SurveyParticipationReportingUserBOService';
export * from './SurveyReportBOService';
export * from './SurveyService';
export * from './SurveyStatsBOService';
export * from './SurveyStatsParticipationBOService';
export * from './TMSModuleCfgBOService';
export * from './TagBOService';
export * from './TagService';
export * from './TeachOnMarsLoginService';
export * from './TeamBoardModuleCfgBOService';
export * from './TeamBoardService';
export * from './TeamEMAORService';
export * from './TeamMemberService';
export * from './TrainingPathBOService';
export * from './TranslationBOService';
export * from './UserActionsService';
export * from './UserBOService';
export * from './UserCommonService';
export * from './UserFonctionPerimeterConfigurationBOService';
export * from './UserRelationService';
export * from './UserSearchService';
export * from './UserService';
export * from './VerbatimCategoryBOService';
export * from './VerbatimsCategoriesBOService';
export * from './VersionService';
export * from './VisualizeBilanService';
export * from './VisualizeService';
export * from './VisualizeSurveyService';
