// ENTITY fr.hiji.metier.certif.dto.back.stats.CertificationStatsModalityInfoBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class CertificationStatsModalityInfoBODTO {

  nbParticipant: number | null = 0;
  nbExamen: number | null = 0;
  nbEchec: number | null = 0;
  nbSuccess: number | null = 0;

  constructor(init?:Partial<CertificationStatsModalityInfoBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.back.stats.CertificationStatsModalityInfoBODTO'] = CertificationStatsModalityInfoBODTO;

reverseMapping[CertificationStatsModalityInfoBODTO.name] = 'fr.hiji.metier.certif.dto.back.stats.CertificationStatsModalityInfoBODTO';

fields['fr.hiji.metier.certif.dto.back.stats.CertificationStatsModalityInfoBODTO']  = {
    nbParticipant: 'java.lang.Integer',
    nbExamen: 'java.lang.Integer',
    nbEchec: 'java.lang.Integer',
    nbSuccess: 'java.lang.Integer'
};
