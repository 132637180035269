// DB ENUM fr.hiji.metier.ema.entities.objectif.EMAObjectiveType generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:59 UTC 2024

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class EMAObjectiveType {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly NUMERIC = new EMAObjectiveType(1, "Numérique", "NUMERIC");
  static readonly TEXTUEL = new EMAObjectiveType(2, "Textuel", "TEXTUEL");

  static readonly values = [
    EMAObjectiveType.NUMERIC,
 
    EMAObjectiveType.TEXTUEL
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of EMAObjectiveType.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of EMAObjectiveType.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.ema.entities.objectif.EMAObjectiveType'] = EMAObjectiveType;
reverseMapping[EMAObjectiveType.name] = 'fr.hiji.metier.ema.entities.objectif.EMAObjectiveType';
