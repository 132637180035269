// ENTITY fr.hiji.metier.mif.entities.formation.Formation generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class Formation {

  idFormation: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  idRepositoryAcquisition: number | null = null;
  idRepositorySatisfactionParticipant: number | null = null;
  idRepositorySatisfactionCommanditaireOpco: number | null = null;
  idQuizEVA: number | null = null;
  trainerCanSeeParticipantSatisfaction: boolean | null = false;
  anonymousSatisfaction: boolean | null = false;
  imageExtension: string | null = '';
  colorCode: string | null = '';
  creationDate: Date | null = null;
  idUserCreator: number | null = 0;
  updateDate: Date | null = null;
  idUserUpdater: number | null = null;
  del: boolean | null = false;

  constructor(init?:Partial<Formation>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.formation.Formation'] = Formation;

reverseMapping[Formation.name] = 'fr.hiji.metier.mif.entities.formation.Formation';

fields['fr.hiji.metier.mif.entities.formation.Formation']  = {
    idFormation: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    idRepositoryAcquisition: 'java.lang.Integer',
    idRepositorySatisfactionParticipant: 'java.lang.Integer',
    idRepositorySatisfactionCommanditaireOpco: 'java.lang.Integer',
    idQuizEVA: 'java.lang.Integer',
    trainerCanSeeParticipantSatisfaction: 'java.lang.Boolean',
    anonymousSatisfaction: 'java.lang.Boolean',
    imageExtension: 'java.lang.String',
    colorCode: 'java.lang.String',
    creationDate: 'java.time.Instant',
    idUserCreator: 'java.lang.Integer',
    updateDate: 'java.time.Instant',
    idUserUpdater: 'java.lang.Integer',
    del: 'java.lang.Boolean'
};
