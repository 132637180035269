// ENTITY fr.hiji.metier.referential.entities.quiz.Question generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:57 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { QuestionType } from './QuestionType';

export class Question {

  idQuestion: number | null = 0;
  question: MultilingualString | null = new MultilingualString();
  image: MultilingualString | null = new MultilingualString();
  description: MultilingualString | null = new MultilingualString();
  explanation: MultilingualString | null = new MultilingualString();
  type: QuestionType | null = null;
  benchmarkCode: string | null = '';
  del: boolean | null = false;

  constructor(init?:Partial<Question>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.referential.entities.quiz.Question'] = Question;

reverseMapping[Question.name] = 'fr.hiji.metier.referential.entities.quiz.Question';

fields['fr.hiji.metier.referential.entities.quiz.Question']  = {
    idQuestion: 'java.lang.Integer',
    question: 'com.ic2.sc.MultilingualString',
    image: 'com.ic2.sc.MultilingualString',
    description: 'com.ic2.sc.MultilingualString',
    explanation: 'com.ic2.sc.MultilingualString',
    type: 'fr.hiji.metier.referential.entities.quiz.QuestionType',
    benchmarkCode: 'java.lang.String',
    del: 'java.lang.Boolean'
};
