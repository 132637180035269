// ENTITY fr.hiji.metier.certif.entities.session.CertificationSessionCandidate generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:59 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class CertificationSessionCandidate {

  idCertificationSession: number | null = 0;
  idUser: number | null = 0;

  constructor(init?:Partial<CertificationSessionCandidate>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.entities.session.CertificationSessionCandidate'] = CertificationSessionCandidate;

reverseMapping[CertificationSessionCandidate.name] = 'fr.hiji.metier.certif.entities.session.CertificationSessionCandidate';

fields['fr.hiji.metier.certif.entities.session.CertificationSessionCandidate']  = {
    idCertificationSession: 'java.lang.Integer',
    idUser: 'java.lang.Integer'
};
