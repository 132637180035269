// ENTITY fr.hiji.metier.verbatim.dto.OGVerbatimDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:59 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class OGVerbatimDTO {

  idObservableGesture: number | null = 0;
  observableGestureName: MultilingualString | null = new MultilingualString();
  nbCategorizedVerbatims: number | null = 0;

  constructor(init?:Partial<OGVerbatimDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.verbatim.dto.OGVerbatimDTO'] = OGVerbatimDTO;

reverseMapping[OGVerbatimDTO.name] = 'fr.hiji.metier.verbatim.dto.OGVerbatimDTO';

fields['fr.hiji.metier.verbatim.dto.OGVerbatimDTO']  = {
    idObservableGesture: 'java.lang.Integer',
    observableGestureName: 'com.ic2.sc.MultilingualString',
    nbCategorizedVerbatims: 'java.lang.Integer'
};
