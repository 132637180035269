// fr.hiji.metier.conf.services.back.ModulesBOServiceI generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:49 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { ModulesBODTO } from '../entities/ModulesBODTO';

@Injectable({
  providedIn: 'root',
})
export class ModulesBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  changeLogo(): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ModulesBOService.changeLogo';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  deleteLogo(): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ModulesBOService.deleteLogo';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateHideHomeTeamButtonsEnabled(enabled: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ModulesBOService.updateHideHomeTeamButtonsEnabled';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(enabled, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getModules(): RpcRequestBuilder<ModulesBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ModulesBOService.getModules';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.conf.dto.ModulesBODTO');
  }

  updateSignupEnabled(enabled: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ModulesBOService.updateSignupEnabled';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(enabled, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateModeMaintenance(maintenance: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ModulesBOService.updateModeMaintenance';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(maintenance, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateNbMiniUsersStats(nbMiniUsersStats: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ModulesBOService.updateNbMiniUsersStats';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(nbMiniUsersStats, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateManualHierarchicalManagementEnabled(enabled: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ModulesBOService.updateManualHierarchicalManagementEnabled';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(enabled, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateEmailAssistanceClient(email: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ModulesBOService.updateEmailAssistanceClient';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(email, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateOpinionEnabled(enabled: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ModulesBOService.updateOpinionEnabled';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(enabled, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateDisplayFunctionFront(display: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ModulesBOService.updateDisplayFunctionFront';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(display, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateUseCampaignAccessControl(use: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ModulesBOService.updateUseCampaignAccessControl';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(use, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateOpinionDataCollectionEnabled(enabled: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ModulesBOService.updateOpinionDataCollectionEnabled';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(enabled, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateHideConnexionDateToGestionnaires(enabled: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ModulesBOService.updateHideConnexionDateToGestionnaires';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(enabled, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateUserImportEnabled(enabled: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ModulesBOService.updateUserImportEnabled';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(enabled, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateEmailUserImportEnabled(enabled: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ModulesBOService.updateEmailUserImportEnabled';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(enabled, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateLocalAccountLoginEnabled(enabled: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ModulesBOService.updateLocalAccountLoginEnabled';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(enabled, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
