// fr.hiji.metier.user.services.front.ProfileServiceI generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:49 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { UserProfileDTO } from '../entities/UserProfileDTO';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getMyProfil(): RpcRequestBuilder<UserProfileDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProfileService.getMyProfil';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.user.dto.UserProfileDTO');
  }

  changeLogo(): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProfileService.changeLogo';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  deleteLogo(): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProfileService.deleteLogo';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateLocale(locale: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ProfileService.updateLocale';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(locale, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
