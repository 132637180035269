// ENTITY fr.hiji.metier.certif.entities.session.CertificationExam generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class CertificationExam {

  idExam: number | null = 0;
  idCertificationSession: number | null = 0;
  idModality: number | null = 0;
  idUser: number | null = 0;
  accessible: boolean | null = false;
  done: boolean | null = false;
  doneDate: Date | null = null;
  catchUp: boolean | null = false;
  shown: boolean | null = false;
  idUserObserver: number | null = 0;
  success: boolean | null = false;

  constructor(init?:Partial<CertificationExam>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.entities.session.CertificationExam'] = CertificationExam;

reverseMapping[CertificationExam.name] = 'fr.hiji.metier.certif.entities.session.CertificationExam';

fields['fr.hiji.metier.certif.entities.session.CertificationExam']  = {
    idExam: 'java.lang.Integer',
    idCertificationSession: 'java.lang.Integer',
    idModality: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    accessible: 'java.lang.Boolean',
    done: 'java.lang.Boolean',
    doneDate: 'java.time.Instant',
    catchUp: 'java.lang.Boolean',
    shown: 'java.lang.Boolean',
    idUserObserver: 'java.lang.Integer',
    success: 'java.lang.Boolean'
};
