// fr.hiji.metier.campaign.services.front.PDIServiceI generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:49 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, MultilingualString } from '@ic2/ic2-lib';
import { PDIResultDTO } from '../entities/PDIResultDTO';
import { PDIObservationDTO } from '../entities/PDIObservationDTO';
import { PDIFormationDTO } from '../entities/PDIFormationDTO';

@Injectable({
  providedIn: 'root',
})
export class PDIService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getPDICampaignThanksMessage(idCampaign: number): RpcRequestBuilder<MultilingualString> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PDIService.getPDICampaignThanksMessage';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.sc.MultilingualString');
  }

  getPDIObservation(idCampaign: number, idUser: number): RpcRequestBuilder<PDIObservationDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PDIService.getPDIObservation';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.campaign.dto.front.pdi.PDIObservationDTO');
  }

  getPDIResult(idCampaign: number, idUser: number): RpcRequestBuilder<PDIResultDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PDIService.getPDIResult';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.campaign.dto.front.pdi.PDIResultDTO');
  }

  savePDI(pdi: PDIObservationDTO, draft: boolean, idUser: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PDIService.savePDI';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(pdi, 'fr.hiji.metier.campaign.dto.front.pdi.PDIObservationDTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(draft, 'java.lang.Boolean', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getPDIFormations(idUser: number): RpcRequestBuilder<PDIFormationDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'PDIService.getPDIFormations';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.campaign.dto.front.pdi.PDIFormationDTO');
  }

}
