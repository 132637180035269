// ENTITY fr.hiji.metier.certif.dto.front.modality.ModalityInstructionDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';

export class ModalityInstructionDTO {

  certificationName: MultilingualString | null = new MultilingualString();
  sessionName: string | null = '';
  user: UserFuncAvatarDTO | null = null;
  modalityName: MultilingualString | null = new MultilingualString();
  instructions: MultilingualString | null = new MultilingualString();

  constructor(init?:Partial<ModalityInstructionDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.front.modality.ModalityInstructionDTO'] = ModalityInstructionDTO;

reverseMapping[ModalityInstructionDTO.name] = 'fr.hiji.metier.certif.dto.front.modality.ModalityInstructionDTO';

fields['fr.hiji.metier.certif.dto.front.modality.ModalityInstructionDTO']  = {
    certificationName: 'com.ic2.sc.MultilingualString',
    sessionName: 'java.lang.String',
    user: 'fr.hiji.metier.user.dto.front.UserFuncAvatarDTO',
    modalityName: 'com.ic2.sc.MultilingualString',
    instructions: 'com.ic2.sc.MultilingualString'
};
