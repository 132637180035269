// ENTITY fr.hiji.metier.ema.dto.back.stats.EMARepositoryStatsBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { EMAObservableGestureStatDTO } from './EMAObservableGestureStatDTO';
import { EMASkillStatBODTO } from './EMASkillStatBODTO';
import { Criteria } from './Criteria';

export class EMARepositoryStatsBODTO {

  skillStats: EMASkillStatBODTO[] | null = [];
  observableGestures: EMAObservableGestureStatDTO[] | null = [];
  criterias: Criteria[] | null = [];

  constructor(init?:Partial<EMARepositoryStatsBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.back.stats.EMARepositoryStatsBODTO'] = EMARepositoryStatsBODTO;

reverseMapping[EMARepositoryStatsBODTO.name] = 'fr.hiji.metier.ema.dto.back.stats.EMARepositoryStatsBODTO';

fields['fr.hiji.metier.ema.dto.back.stats.EMARepositoryStatsBODTO']  = {
    skillStats: 'java.util.List<fr.hiji.metier.ema.dto.back.stats.EMASkillStatBODTO>',
    observableGestures: 'java.util.List<fr.hiji.metier.ema.dto.back.stats.EMAObservableGestureStatDTO>',
    criterias: 'java.util.List<fr.hiji.metier.referential.entities.Criteria>'
};
