// ENTITY fr.hiji.metier.ema.entities.objectif.EMAObjectiveResult generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class EMAObjectiveResult {

  idEMAObjective: number | null = 0;
  idUser: number | null = 0;
  creationDate: Date | null = null;
  idUserCreator: number | null = null;
  objective: number | null = null;
  deadlineDate: Date | null = null;
  result: number | null = null;
  comment: string | null = '';
  monthly: boolean | null = false;
  idEMA: number | null = null;
  importFilename: string | null = '';
  ranking: number | null = 0;

  constructor(init?:Partial<EMAObjectiveResult>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.entities.objectif.EMAObjectiveResult'] = EMAObjectiveResult;

reverseMapping[EMAObjectiveResult.name] = 'fr.hiji.metier.ema.entities.objectif.EMAObjectiveResult';

fields['fr.hiji.metier.ema.entities.objectif.EMAObjectiveResult']  = {
    idEMAObjective: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    creationDate: 'java.time.Instant',
    idUserCreator: 'java.lang.Integer',
    objective: 'java.lang.Double',
    deadlineDate: 'java.time.LocalDate',
    result: 'java.lang.Double',
    comment: 'java.lang.String',
    monthly: 'java.lang.Boolean',
    idEMA: 'java.lang.Integer',
    importFilename: 'java.lang.String',
    ranking: 'java.lang.Integer'
};
