// ENTITY fr.hiji.metier.campaign.dto.back.survey.SurveyUserBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:57 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { SurveyUser } from './SurveyUser';

export class SurveyUserBODTO {

  firstName: string | null = '';
  lastName: string | null = '';
  fonction: string | null = '';
  avatarExtension: string | null = '';
  userDel: boolean | null = false;
  answerDate: Date | null = null;
  draft: boolean | null = false;
  surveyUser: SurveyUser | null = null;

  constructor(init?:Partial<SurveyUserBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back.survey.SurveyUserBODTO'] = SurveyUserBODTO;

reverseMapping[SurveyUserBODTO.name] = 'fr.hiji.metier.campaign.dto.back.survey.SurveyUserBODTO';

fields['fr.hiji.metier.campaign.dto.back.survey.SurveyUserBODTO']  = {
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    fonction: 'java.lang.String',
    avatarExtension: 'java.lang.String',
    userDel: 'java.lang.Boolean',
    answerDate: 'java.time.Instant',
    draft: 'java.lang.Boolean',
    surveyUser: 'fr.hiji.metier.campaign.entities.survey.SurveyUser'
};
