// ENTITY fr.hiji.metier.user.dto.ImportResultDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ImportResultDTO {

  nbCreated: number | null = 0;
  nbUpdated: number | null = 0;
  nbDeleted: number | null = 0;
  nbSkipped: number | null = 0;
  nbDuplicate: number | null = 0;
  nbManagerNotFound: number | null = 0;

  constructor(init?:Partial<ImportResultDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.user.dto.ImportResultDTO'] = ImportResultDTO;

reverseMapping[ImportResultDTO.name] = 'fr.hiji.metier.user.dto.ImportResultDTO';

fields['fr.hiji.metier.user.dto.ImportResultDTO']  = {
    nbCreated: 'java.lang.Integer',
    nbUpdated: 'java.lang.Integer',
    nbDeleted: 'java.lang.Integer',
    nbSkipped: 'java.lang.Integer',
    nbDuplicate: 'java.lang.Integer',
    nbManagerNotFound: 'java.lang.Integer'
};
