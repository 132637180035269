// fr.hiji.metier.mif.services.front.FormationSessionManagerServiceI generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:49 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { FormationSessionDTO } from '../entities/FormationSessionDTO';
import { LayoutManagerDTO } from '../entities/LayoutManagerDTO';
import { MenuManagerDTO } from '../entities/MenuManagerDTO';

@Injectable({
  providedIn: 'root',
})
export class FormationSessionManagerService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getFormationSessionsParticipantManager(idUser: number): RpcRequestBuilder<FormationSessionDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionManagerService.getFormationSessionsParticipantManager';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getLayoutManager(idFormation: number, idFormationSession: number, idUser: number): RpcRequestBuilder<LayoutManagerDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionManagerService.getLayoutManager';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormation, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.front.manager.LayoutManagerDTO');
  }

  getSessionMenuManager(idFormationSession: number, idUser: number): RpcRequestBuilder<MenuManagerDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionManagerService.getSessionMenuManager';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.front.manager.MenuManagerDTO');
  }

}
