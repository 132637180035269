// fr.hiji.metier.ema.services.front.EMAOGHistoServiceI generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:49 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { EMAOGHistogramDTO } from '../entities/EMAOGHistogramDTO';

@Injectable({
  providedIn: 'root',
})
export class EMAOGHistoService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getHistogram(idUser: number, idObservableGesture: number, idEMAUserOGC: number): RpcRequestBuilder<EMAOGHistogramDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EMAOGHistoService.getHistogram';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idObservableGesture, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idEMAUserOGC, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

}
