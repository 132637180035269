// ENTITY fr.hiji.metier.fbs.dto.FeedbackDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FeedbackUserDTO } from './FeedbackUserDTO';
import { Feedback } from './Feedback';
import { FeedbackReactionDTO } from './FeedbackReactionDTO';
import { FeedbackCategory } from './FeedbackCategory';

export class FeedbackDTO {

  feedback: Feedback | null = null;
  firstName: string | null = '';
  lastName: string | null = '';
  avatarExtension: string | null = '';
  fonction: string | null = '';
  category: FeedbackCategory | null = null;
  users: FeedbackUserDTO[] | null = [];
  reactions: FeedbackReactionDTO[] | null = [];

  constructor(init?:Partial<FeedbackDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.fbs.dto.FeedbackDTO'] = FeedbackDTO;

reverseMapping[FeedbackDTO.name] = 'fr.hiji.metier.fbs.dto.FeedbackDTO';

fields['fr.hiji.metier.fbs.dto.FeedbackDTO']  = {
    feedback: 'fr.hiji.metier.fbs.entities.Feedback',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    avatarExtension: 'java.lang.String',
    fonction: 'java.lang.String',
    category: 'fr.hiji.metier.fbs.entities.FeedbackCategory',
    users: 'java.util.List<fr.hiji.metier.fbs.dto.FeedbackUserDTO>',
    reactions: 'java.util.List<fr.hiji.metier.fbs.dto.FeedbackReactionDTO>'
};
