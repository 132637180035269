// ENTITY fr.hiji.metier.certif.dto.back.certificationsession.CertificationSessionExamBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:59 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { ModalityType } from './ModalityType';

export class CertificationSessionExamBODTO {

  idModality: number | null = 0;
  modalityType: ModalityType | null = null;
  idUser: number | null = 0;
  userFullName: string | null = '';
  idExam: number | null = 0;
  accessible: boolean | null = false;
  done: boolean | null = false;
  catchUp: boolean | null = false;
  success: boolean | null = false;
  doneDate: Date | null = null;
  idUserObserver: number | null = 0;
  fullNameUserObserver: string | null = '';
  quizzScore: number | null = 0;
  quizzTotal: number | null = 0;
  roleplayScore: number | null = 0;
  interviewScore: number | null = 0;

  constructor(init?:Partial<CertificationSessionExamBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.back.certificationsession.CertificationSessionExamBODTO'] = CertificationSessionExamBODTO;

reverseMapping[CertificationSessionExamBODTO.name] = 'fr.hiji.metier.certif.dto.back.certificationsession.CertificationSessionExamBODTO';

fields['fr.hiji.metier.certif.dto.back.certificationsession.CertificationSessionExamBODTO']  = {
    idModality: 'java.lang.Integer',
    modalityType: 'fr.hiji.metier.certif.entities.modality.ModalityType',
    idUser: 'java.lang.Integer',
    userFullName: 'java.lang.String',
    idExam: 'java.lang.Integer',
    accessible: 'java.lang.Boolean',
    done: 'java.lang.Boolean',
    catchUp: 'java.lang.Boolean',
    success: 'java.lang.Boolean',
    doneDate: 'java.time.Instant',
    idUserObserver: 'java.lang.Integer',
    fullNameUserObserver: 'java.lang.String',
    quizzScore: 'java.lang.Double',
    quizzTotal: 'java.lang.Double',
    roleplayScore: 'java.lang.Double',
    interviewScore: 'java.lang.Double'
};
