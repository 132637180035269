// ENTITY fr.hiji.metier.campaign.dto.back.stats.SkillStatBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:59 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { SkillStatPerObservationTypeBODTO } from './SkillStatPerObservationTypeBODTO';

export class SkillStatBODTO {

  idTag: number | null = 0;
  tagName: MultilingualString | null = new MultilingualString();
  colorCode: string | null = '';
  showInRadar: boolean | null = false;
  obsTypes: SkillStatPerObservationTypeBODTO[] | null = [];

  constructor(init?:Partial<SkillStatBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back.stats.SkillStatBODTO'] = SkillStatBODTO;

reverseMapping[SkillStatBODTO.name] = 'fr.hiji.metier.campaign.dto.back.stats.SkillStatBODTO';

fields['fr.hiji.metier.campaign.dto.back.stats.SkillStatBODTO']  = {
    idTag: 'java.lang.Integer',
    tagName: 'com.ic2.sc.MultilingualString',
    colorCode: 'java.lang.String',
    showInRadar: 'java.lang.Boolean',
    obsTypes: 'java.util.List<fr.hiji.metier.campaign.dto.back.stats.SkillStatPerObservationTypeBODTO>'
};
