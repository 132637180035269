// fr.hiji.metier.user.services.front.RegisterServiceI generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:49 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { LoginResponse } from '../entities/LoginResponse';
import { EmailAvailability } from '../entities/EmailAvailability';
import { UserRegisterDTO } from '../entities/UserRegisterDTO';

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  register(firstName: string, lastName: string, email: string, password: string, origin: string): RpcRequestBuilder<LoginResponse> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'RegisterService.register';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(firstName, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(lastName, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(email, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(password, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(origin, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.services.dto.LoginResponse');
  }

  getInfos(email: string): RpcRequestBuilder<UserRegisterDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'RegisterService.getInfos';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(email, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.user.dto.UserRegisterDTO');
  }

  emailAvailable(email: string): RpcRequestBuilder<EmailAvailability> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'RegisterService.emailAvailable';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(email, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.user.dto.EmailAvailability');
  }

  registerPassword(email: string, password: string, origin: string): RpcRequestBuilder<LoginResponse> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'RegisterService.registerPassword';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(email, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(password, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(origin, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.services.dto.LoginResponse');
  }

  askForLink(email: string, back: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'RegisterService.askForLink';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(email, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(back, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
