import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'hiji-formation-quiz-send-email-modal',
  templateUrl: './formation-quiz-send-email-modal.component.html',
  styleUrl: './formation-quiz-send-email-modal.component.scss',
})
export class FormationQuizSendEmailModalComponent {
  loading: boolean = false;

  constructor(public modal: NgbActiveModal) {}
}
