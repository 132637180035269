// fr.hiji.metier.mif.services.front.event.FormationEventSatisfactionServiceI generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:49 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { FormationEventSatisfactionDTO } from '../entities/FormationEventSatisfactionDTO';

@Injectable({
  providedIn: 'root',
})
export class FormationEventSatisfactionService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getSatisfaction(idFormationEvent: number): RpcRequestBuilder<FormationEventSatisfactionDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationEventSatisfactionService.getSatisfaction';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationEvent, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.front.event.FormationEventSatisfactionDTO');
  }

  saveSatisfactionObservations(dto: FormationEventSatisfactionDTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationEventSatisfactionService.saveSatisfactionObservations';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dto, 'fr.hiji.metier.mif.dto.front.event.FormationEventSatisfactionDTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
