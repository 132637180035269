// fr.hiji.metier.mif.services.front.FormationSessionSatisfactionServiceI generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:49 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { SatisfactionRepositoryAnswerDTO } from '../entities/SatisfactionRepositoryAnswerDTO';

@Injectable({
  providedIn: 'root',
})
export class FormationSessionSatisfactionService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getSatisfactionParticipant(idFormationSession: number, idParticipant: number): RpcRequestBuilder<SatisfactionRepositoryAnswerDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionSatisfactionService.getSatisfactionParticipant';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idParticipant, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.front.satisfaction.SatisfactionRepositoryAnswerDTO');
  }

  saveParticipantSatisfaction(dto: SatisfactionRepositoryAnswerDTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionSatisfactionService.saveParticipantSatisfaction';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dto, 'fr.hiji.metier.mif.dto.front.satisfaction.SatisfactionRepositoryAnswerDTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
