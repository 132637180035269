// ENTITY fr.hiji.metier.mif.dto.back.trainingpath.TrainingPathItemBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class TrainingPathItemBODTO {

  idTrainingPath: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  del: boolean | null = false;
  populationName: MultilingualString | null = new MultilingualString();
  populationDel: boolean | null = false;
  nbUsersTargeted: number | null = 0;
  nbTraining: number | null = 0;
  nbTrainingDel: number | null = 0;

  constructor(init?:Partial<TrainingPathItemBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.trainingpath.TrainingPathItemBODTO'] = TrainingPathItemBODTO;

reverseMapping[TrainingPathItemBODTO.name] = 'fr.hiji.metier.mif.dto.back.trainingpath.TrainingPathItemBODTO';

fields['fr.hiji.metier.mif.dto.back.trainingpath.TrainingPathItemBODTO']  = {
    idTrainingPath: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    del: 'java.lang.Boolean',
    populationName: 'com.ic2.sc.MultilingualString',
    populationDel: 'java.lang.Boolean',
    nbUsersTargeted: 'java.lang.Integer',
    nbTraining: 'java.lang.Integer',
    nbTrainingDel: 'java.lang.Integer'
};
