// ENTITY fr.hiji.metier.mif.entities.session.FormationSessionDiffusionList generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class FormationSessionDiffusionList {

  idFormationSession: number | null = 0;
  idDiffusionList: number | null = 0;
  nbParticipantSlots: number | null = 0;
  nbQueueSlots: number | null = 0;

  constructor(init?:Partial<FormationSessionDiffusionList>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.session.FormationSessionDiffusionList'] = FormationSessionDiffusionList;

reverseMapping[FormationSessionDiffusionList.name] = 'fr.hiji.metier.mif.entities.session.FormationSessionDiffusionList';

fields['fr.hiji.metier.mif.entities.session.FormationSessionDiffusionList']  = {
    idFormationSession: 'java.lang.Integer',
    idDiffusionList: 'java.lang.Integer',
    nbParticipantSlots: 'java.lang.Integer',
    nbQueueSlots: 'java.lang.Integer'
};
