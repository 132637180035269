// ENTITY fr.hiji.metier.visualize.dto.front.VizSurveyDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:59 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class VizSurveyDTO {

  idCampaign: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  startDate: Date | null = null;
  endDate: Date | null = null;
  surveyResultsAvailableDate: Date | null = null;

  constructor(init?:Partial<VizSurveyDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.visualize.dto.front.VizSurveyDTO'] = VizSurveyDTO;

reverseMapping[VizSurveyDTO.name] = 'fr.hiji.metier.visualize.dto.front.VizSurveyDTO';

fields['fr.hiji.metier.visualize.dto.front.VizSurveyDTO']  = {
    idCampaign: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    startDate: 'java.time.LocalDate',
    endDate: 'java.time.LocalDate',
    surveyResultsAvailableDate: 'java.time.LocalDate'
};
