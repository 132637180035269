// fr.hiji.metier.page.services.back.StaticPageBOServiceI generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:49 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter } from '@ic2/ic2-lib';
import { StaticPage } from '../entities/StaticPage';
import { StaticPageBODTO } from '../entities/StaticPageBODTO';

@Injectable({
  providedIn: 'root',
})
export class StaticPageBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getStaticPages(filter: GenericFilter): RpcRequestBuilder<ListWithCount<StaticPage>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'StaticPageBOService.getStaticPages';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getStaticPage(idStaticPage: number): RpcRequestBuilder<StaticPageBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'StaticPageBOService.getStaticPage';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idStaticPage, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.page.dto.back.StaticPageBODTO');
  }

  saveStaticPage(sp: StaticPageBODTO): RpcRequestBuilder<StaticPageBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'StaticPageBOService.saveStaticPage';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(sp, 'fr.hiji.metier.page.dto.back.StaticPageBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.page.dto.back.StaticPageBODTO');
  }

}
