// ENTITY fr.hiji.metier.reporting.entities.survey.SurveyReportObservableGesture generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class SurveyReportObservableGesture {

  idSurveyReport: number | null = 0;
  idObservableGesture: number | null = 0;
  nb: number | null = 0;
  avg: number | null = null;
  nbValue1: number | null = 0;
  nbValue2: number | null = 0;
  nbValue3: number | null = 0;
  nbValue4: number | null = 0;
  nbValue5: number | null = 0;
  nbNps0: number | null = 0;
  nbNps10: number | null = 0;
  nbNps20: number | null = 0;
  nbNps30: number | null = 0;
  nbNps40: number | null = 0;
  nbNps50: number | null = 0;
  nbNps60: number | null = 0;
  nbNps70: number | null = 0;
  nbNps80: number | null = 0;
  nbNps90: number | null = 0;
  nbNps100: number | null = 0;

  constructor(init?:Partial<SurveyReportObservableGesture>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.entities.survey.SurveyReportObservableGesture'] = SurveyReportObservableGesture;

reverseMapping[SurveyReportObservableGesture.name] = 'fr.hiji.metier.reporting.entities.survey.SurveyReportObservableGesture';

fields['fr.hiji.metier.reporting.entities.survey.SurveyReportObservableGesture']  = {
    idSurveyReport: 'java.lang.Integer',
    idObservableGesture: 'java.lang.Integer',
    nb: 'java.lang.Integer',
    avg: 'java.lang.Double',
    nbValue1: 'java.lang.Integer',
    nbValue2: 'java.lang.Integer',
    nbValue3: 'java.lang.Integer',
    nbValue4: 'java.lang.Integer',
    nbValue5: 'java.lang.Integer',
    nbNps0: 'java.lang.Integer',
    nbNps10: 'java.lang.Integer',
    nbNps20: 'java.lang.Integer',
    nbNps30: 'java.lang.Integer',
    nbNps40: 'java.lang.Integer',
    nbNps50: 'java.lang.Integer',
    nbNps60: 'java.lang.Integer',
    nbNps70: 'java.lang.Integer',
    nbNps80: 'java.lang.Integer',
    nbNps90: 'java.lang.Integer',
    nbNps100: 'java.lang.Integer'
};
