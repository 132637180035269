// ENTITY fr.hiji.metier.ema.dto.back.stats.EMASkillStatBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class EMASkillStatBODTO {

  idTag: number | null = 0;
  tagName: MultilingualString | null = new MultilingualString();
  colorCode: string | null = '';
  showInRadar: boolean | null = false;
  avgObs: number | null = null;
  avgObsFirstHalf: number | null = null;
  avgObsSecondHalf: number | null = null;
  avgAutoObs: number | null = null;
  avgAutoObsFirstHalf: number | null = null;
  avgAutoObsSecondHalf: number | null = null;
  nbGesteMaitrise: number | null = 0;
  nbGesteTotal: number | null = 0;

  constructor(init?:Partial<EMASkillStatBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.back.stats.EMASkillStatBODTO'] = EMASkillStatBODTO;

reverseMapping[EMASkillStatBODTO.name] = 'fr.hiji.metier.ema.dto.back.stats.EMASkillStatBODTO';

fields['fr.hiji.metier.ema.dto.back.stats.EMASkillStatBODTO']  = {
    idTag: 'java.lang.Integer',
    tagName: 'com.ic2.sc.MultilingualString',
    colorCode: 'java.lang.String',
    showInRadar: 'java.lang.Boolean',
    avgObs: 'java.lang.Double',
    avgObsFirstHalf: 'java.lang.Double',
    avgObsSecondHalf: 'java.lang.Double',
    avgAutoObs: 'java.lang.Double',
    avgAutoObsFirstHalf: 'java.lang.Double',
    avgAutoObsSecondHalf: 'java.lang.Double',
    nbGesteMaitrise: 'java.lang.Integer',
    nbGesteTotal: 'java.lang.Integer'
};
