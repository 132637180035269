// ENTITY fr.hiji.metier.mif.dto.back.formation.FormationFileBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { HijiFile } from './HijiFile';
import { FormationFile } from './FormationFile';

export class FormationFileBODTO {

  formationFile: FormationFile | null = null;
  hijiFile: HijiFile | null = null;
  token: string | null = '';

  constructor(init?:Partial<FormationFileBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.formation.FormationFileBODTO'] = FormationFileBODTO;

reverseMapping[FormationFileBODTO.name] = 'fr.hiji.metier.mif.dto.back.formation.FormationFileBODTO';

fields['fr.hiji.metier.mif.dto.back.formation.FormationFileBODTO']  = {
    formationFile: 'fr.hiji.metier.mif.entities.formation.FormationFile',
    hijiFile: 'fr.hiji.metier.file.entities.HijiFile',
    token: 'java.lang.String'
};
