// ENTITY fr.hiji.metier.teamboard.dto.TeamBoardLiteDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';

export class TeamBoardLiteDTO {

  idTeamBoard: number | null = 0;
  creationDate: Date | null = null;
  visible: boolean | null = false;
  manager: UserFuncAvatarDTO | null = null;
  lastBriefDate: Date | null = null;
  sawTeamboard: boolean | null = false;
  sawLastBrief: boolean | null = false;

  constructor(init?:Partial<TeamBoardLiteDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.teamboard.dto.TeamBoardLiteDTO'] = TeamBoardLiteDTO;

reverseMapping[TeamBoardLiteDTO.name] = 'fr.hiji.metier.teamboard.dto.TeamBoardLiteDTO';

fields['fr.hiji.metier.teamboard.dto.TeamBoardLiteDTO']  = {
    idTeamBoard: 'java.lang.Integer',
    creationDate: 'java.time.Instant',
    visible: 'java.lang.Boolean',
    manager: 'fr.hiji.metier.user.dto.front.UserFuncAvatarDTO',
    lastBriefDate: 'java.time.Instant',
    sawTeamboard: 'java.lang.Boolean',
    sawLastBrief: 'java.lang.Boolean'
};
