// ENTITY fr.hiji.metier.certif.dto.back.modality.roleplay.ModalityRoleplayBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { ModalityRoleplay } from './ModalityRoleplay';
import { ModalityRoleplayTarget } from './ModalityRoleplayTarget';

export class ModalityRoleplayBODTO {

  modalityRoleplay: ModalityRoleplay | null = null;
  targets: ModalityRoleplayTarget[] | null = [];
  repositoryName: MultilingualString | null = new MultilingualString();
  repositoryDel: boolean | null = false;

  constructor(init?:Partial<ModalityRoleplayBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.back.modality.roleplay.ModalityRoleplayBODTO'] = ModalityRoleplayBODTO;

reverseMapping[ModalityRoleplayBODTO.name] = 'fr.hiji.metier.certif.dto.back.modality.roleplay.ModalityRoleplayBODTO';

fields['fr.hiji.metier.certif.dto.back.modality.roleplay.ModalityRoleplayBODTO']  = {
    modalityRoleplay: 'fr.hiji.metier.certif.entities.modality.roleplay.ModalityRoleplay',
    targets: 'java.util.List<fr.hiji.metier.certif.entities.modality.roleplay.ModalityRoleplayTarget>',
    repositoryName: 'com.ic2.sc.MultilingualString',
    repositoryDel: 'java.lang.Boolean'
};
