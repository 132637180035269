// ENTITY fr.hiji.metier.ema.entities.EMARDV generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class EMARDV {

  idUserObserved: number | null = 0;
  idUserManager: number | null = 0;
  date: Date | null = null;
  nextObsHint: Date | null = null;
  nextAutoObsHint: Date | null = null;
  nbObsToDo: number | null = 0;
  idEMA: number | null = null;

  constructor(init?:Partial<EMARDV>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.entities.EMARDV'] = EMARDV;

reverseMapping[EMARDV.name] = 'fr.hiji.metier.ema.entities.EMARDV';

fields['fr.hiji.metier.ema.entities.EMARDV']  = {
    idUserObserved: 'java.lang.Integer',
    idUserManager: 'java.lang.Integer',
    date: 'java.time.Instant',
    nextObsHint: 'java.time.LocalDate',
    nextAutoObsHint: 'java.time.LocalDate',
    nbObsToDo: 'java.lang.Integer',
    idEMA: 'java.lang.Integer'
};
