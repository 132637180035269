// ENTITY fr.hiji.metier.campaign.dto.back.survey.SurveyParticipationReportingUserBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';

export class SurveyParticipationReportingUserBODTO {

  user: UserFuncAvatarDTO | null = null;
  perimeters: string[] | null = [];

  constructor(init?:Partial<SurveyParticipationReportingUserBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back.survey.SurveyParticipationReportingUserBODTO'] = SurveyParticipationReportingUserBODTO;

reverseMapping[SurveyParticipationReportingUserBODTO.name] = 'fr.hiji.metier.campaign.dto.back.survey.SurveyParticipationReportingUserBODTO';

fields['fr.hiji.metier.campaign.dto.back.survey.SurveyParticipationReportingUserBODTO']  = {
    user: 'fr.hiji.metier.user.dto.front.UserFuncAvatarDTO',
    perimeters: 'java.util.List<java.lang.String>'
};
