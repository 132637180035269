// ENTITY fr.hiji.metier.conf.dto.submodules.ModuleFBSDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:59 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModuleFBSDTO {

  moduleFBS: boolean | null = false;
  fbsFeaturePopulation: number | null = 0;
  fbsSendLimitedToAroundMe: boolean | null = false;
  fbsReceivedNotifMailEnabled: boolean | null = false;
  fbsRecapMailEnabled: boolean | null = false;
  displayFBSHomeButton: boolean | null = false;
  fbsSolicitationMailEnabled: boolean | null = false;

  constructor(init?:Partial<ModuleFBSDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.submodules.ModuleFBSDTO'] = ModuleFBSDTO;

reverseMapping[ModuleFBSDTO.name] = 'fr.hiji.metier.conf.dto.submodules.ModuleFBSDTO';

fields['fr.hiji.metier.conf.dto.submodules.ModuleFBSDTO']  = {
    moduleFBS: 'java.lang.Boolean',
    fbsFeaturePopulation: 'java.lang.Integer',
    fbsSendLimitedToAroundMe: 'java.lang.Boolean',
    fbsReceivedNotifMailEnabled: 'java.lang.Boolean',
    fbsRecapMailEnabled: 'java.lang.Boolean',
    displayFBSHomeButton: 'java.lang.Boolean',
    fbsSolicitationMailEnabled: 'java.lang.Boolean'
};
