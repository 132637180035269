export class Config {
  production: boolean;
  rpcHost: string; //defined by server config cannot be overwritten
  foUrl: string; //defined by server config cannot be overwritten
  boUrl: string; //defined by server config cannot be overwritten
  publicFileUrl: string; //defined by server config cannot be overwritten
  themeColor: string;
  appTitle: string;
  logRpcRequests: boolean;
  defaultLanguage: string;
  availableLanguages: string[];
  vapidPublicKey: string;
  mailAuthentication: boolean = false;
  resetPasswordViaPinCode: boolean = false; //defined by server config cannot be overwritten
  tomUrlPerInstance: object = {}; //Teach on mars urls
  css: string = null;
  localAccountLoginEnabled: boolean = true; //defined by server config cannot be overwritten
  signupEnabled: boolean = true; //defined by server config cannot be overwritten
  hasSSOOtherThanHiji: boolean = true; //defined by server config cannot be overwritten
  client: string = null; //defined by server config cannot be overwritten
  environment: string = null; //defined by server config cannot be overwritten

  //TODO MIGRER TOUT CE QUI EST EN DESSOUS DANS MODULES DTO
  showFrontLanguageChange: boolean;
  hideProgress: boolean = false;
  hideDiscover: boolean = false;
  hideVisualize: boolean = false;
  forceGDPRAcceptation: boolean = true;
  allowEditEmailSender: boolean = false;
  showContactHelp: boolean = true;
  hideHomeEncouragePictureCard: boolean = false;
  hideQualiopiMIF: boolean = false;
  hideQuizMIF: boolean = false;
  showEMAManagerExchangeWithTeamRepositoryDetails: boolean = false;
  defaultMIFEnabledOptions = {
    acquisitionEnabled: false,
    expectationsParticipantEnabled: false,
    expectationsParticipantEvaluationEnabled: false,
    expectationsManagerEnabled: false,
    activationEnabled: false,
    actionEnabled: false,
    actionEvaluationJ10Enabled: false,
    attendanceEnabled: false,
    quizQualiopiEnabled: false,
  };
  enable360HHEGImport: boolean = false;
  warningHomeMessage: string = null;
  hideHierarchyInMyProfile: boolean = false;
  lemonProjectKey: string = '';
  //WARNING READ

  //Things added here will be undefined if not set in the front config
  //Update model.config.json in hiji-server too when adding things here
}
