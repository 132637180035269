// ENTITY fr.hiji.metier.home.dto.front.InquiryAnswerDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:57 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class InquiryAnswerDTO {

  idInquiry: number | null = 0;
  idUser: number | null = 0;
  firstName: string | null = '';
  lastName: string | null = '';
  avatarExtension: string | null = '';
  name: MultilingualString | null = new MultilingualString();
  endDate: Date | null = null;
  draft: boolean | null = false;

  constructor(init?:Partial<InquiryAnswerDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.home.dto.front.InquiryAnswerDTO'] = InquiryAnswerDTO;

reverseMapping[InquiryAnswerDTO.name] = 'fr.hiji.metier.home.dto.front.InquiryAnswerDTO';

fields['fr.hiji.metier.home.dto.front.InquiryAnswerDTO']  = {
    idInquiry: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    avatarExtension: 'java.lang.String',
    name: 'com.ic2.sc.MultilingualString',
    endDate: 'java.time.LocalDate',
    draft: 'java.lang.Boolean'
};
