// ENTITY fr.hiji.metier.user.dto.front.UserActionsDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { CampaignRepositoryAccessDTO } from './CampaignRepositoryAccessDTO';
import { UserActionsEMADTO } from './UserActionsEMADTO';
import { TeamMemberDTO } from './TeamMemberDTO';
import { KpiDTO } from './KpiDTO';
import { PDIDTO } from './PDIDTO';

export class UserActionsDTO {

  member: TeamMemberDTO | null = null;
  campaigns: CampaignRepositoryAccessDTO[] | null = [];
  PDIs: PDIDTO[] | null = [];
  isTeamMemberInvitationPending: boolean | null = false;
  isInMyTeam: boolean | null = false;
  isInMyGlobalTeam: boolean | null = false;
  userActionsEMADTO: UserActionsEMADTO | null = null;
  kpi: KpiDTO[] | null = [];

  constructor(init?:Partial<UserActionsDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.user.dto.front.UserActionsDTO'] = UserActionsDTO;

reverseMapping[UserActionsDTO.name] = 'fr.hiji.metier.user.dto.front.UserActionsDTO';

fields['fr.hiji.metier.user.dto.front.UserActionsDTO']  = {
    member: 'fr.hiji.metier.user.dto.front.TeamMemberDTO',
    campaigns: 'java.util.List<fr.hiji.metier.campaign.dto.front.CampaignRepositoryAccessDTO>',
    PDIs: 'java.util.List<fr.hiji.metier.campaign.dto.front.pdi.PDIDTO>',
    isTeamMemberInvitationPending: 'java.lang.Boolean',
    isInMyTeam: 'java.lang.Boolean',
    isInMyGlobalTeam: 'java.lang.Boolean',
    userActionsEMADTO: 'fr.hiji.metier.user.dto.front.ema.UserActionsEMADTO',
    kpi: 'java.util.List<fr.hiji.metier.user.dto.front.KpiDTO>'
};
