// ENTITY fr.hiji.metier.certif.dto.front.certificationsession.CertificationSessionsDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:59 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { CertificationSessionDTO } from './CertificationSessionDTO';

export class CertificationSessionsDTO {

  idCertification: number | null = 0;
  certificationImageExtension: string | null = '';
  certificationName: MultilingualString | null = new MultilingualString();
  sessions: CertificationSessionDTO[] | null = [];
  hasResults: boolean | null = false;

  constructor(init?:Partial<CertificationSessionsDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.front.certificationsession.CertificationSessionsDTO'] = CertificationSessionsDTO;

reverseMapping[CertificationSessionsDTO.name] = 'fr.hiji.metier.certif.dto.front.certificationsession.CertificationSessionsDTO';

fields['fr.hiji.metier.certif.dto.front.certificationsession.CertificationSessionsDTO']  = {
    idCertification: 'java.lang.Integer',
    certificationImageExtension: 'java.lang.String',
    certificationName: 'com.ic2.sc.MultilingualString',
    sessions: 'java.util.List<fr.hiji.metier.certif.dto.front.certificationsession.CertificationSessionDTO>',
    hasResults: 'java.lang.Boolean'
};
