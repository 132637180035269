// ENTITY fr.hiji.metier.mif.dto.front.trainer.RepositoryResultDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:59 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FormationObservationObservableGesture } from './FormationObservationObservableGesture';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';
import { FormationObservation } from './FormationObservation';
import { ObservableGestureDTO } from './ObservableGestureDTO';
import { Criteria } from './Criteria';

export class RepositoryResultDTO {

  fo: FormationObservation[] | null = [];
  foog: FormationObservationObservableGesture[] | null = [];
  users: UserFuncAvatarDTO[] | null = [];
  ogs: ObservableGestureDTO[] | null = [];
  criterias: Criteria[] | null = [];

  constructor(init?:Partial<RepositoryResultDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.trainer.RepositoryResultDTO'] = RepositoryResultDTO;

reverseMapping[RepositoryResultDTO.name] = 'fr.hiji.metier.mif.dto.front.trainer.RepositoryResultDTO';

fields['fr.hiji.metier.mif.dto.front.trainer.RepositoryResultDTO']  = {
    fo: 'java.util.List<fr.hiji.metier.mif.entities.observation.FormationObservation>',
    foog: 'java.util.List<fr.hiji.metier.mif.entities.observation.FormationObservationObservableGesture>',
    users: 'java.util.List<fr.hiji.metier.user.dto.front.UserFuncAvatarDTO>',
    ogs: 'java.util.List<fr.hiji.metier.campaign.dto.front.ObservableGestureDTO>',
    criterias: 'java.util.List<fr.hiji.metier.referential.entities.Criteria>'
};
