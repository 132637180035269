// ENTITY fr.hiji.metier.user.dto.HijiBundle generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:59 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { ModulesDTO } from './ModulesDTO';
import { HijiUser } from './HijiUser';

export class HijiBundle {

  hijiUser: HijiUser | null = null;
  avatarUrl: string | null = '';
  fonctionName: string | null = '';
  modules: ModulesDTO | null = null;
  tokenExpiration: Date | null = null;
  idRegion: number | null = 0;
  idsPerimetre: number[] | null = [];

  constructor(init?:Partial<HijiBundle>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.user.dto.HijiBundle'] = HijiBundle;

reverseMapping[HijiBundle.name] = 'fr.hiji.metier.user.dto.HijiBundle';

fields['fr.hiji.metier.user.dto.HijiBundle']  = {
    hijiUser: 'fr.hiji.metier.user.entities.HijiUser',
    avatarUrl: 'java.lang.String',
    fonctionName: 'java.lang.String',
    modules: 'fr.hiji.metier.conf.dto.ModulesDTO',
    tokenExpiration: 'java.time.Instant',
    idRegion: 'java.lang.Integer',
    idsPerimetre: 'java.util.List<java.lang.Integer>'
};
