// ENTITY fr.hiji.metier.mif.entities.quiz.FormationQuizAnswerSkillAvg generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class FormationQuizAnswerSkillAvg {

  idFormationQuizAnswer: number | null = 0;
  idTag: number | null = 0;
  score: number | null = 0;
  total: number | null = 0;

  constructor(init?:Partial<FormationQuizAnswerSkillAvg>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.quiz.FormationQuizAnswerSkillAvg'] = FormationQuizAnswerSkillAvg;

reverseMapping[FormationQuizAnswerSkillAvg.name] = 'fr.hiji.metier.mif.entities.quiz.FormationQuizAnswerSkillAvg';

fields['fr.hiji.metier.mif.entities.quiz.FormationQuizAnswerSkillAvg']  = {
    idFormationQuizAnswer: 'java.lang.Integer',
    idTag: 'java.lang.Integer',
    score: 'java.lang.Double',
    total: 'java.lang.Double'
};
