// DB ENUM fr.hiji.metier.mif.entities.session.FormationSessionQuizzResultMode generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:59 UTC 2024

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class FormationSessionQuizzResultMode {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly NO_RESULT = new FormationSessionQuizzResultMode(1, "NO_RESULT", "NO_RESULT");
  static readonly SCORE = new FormationSessionQuizzResultMode(2, "SCORE", "SCORE");
  static readonly SCORE_ANSWERS = new FormationSessionQuizzResultMode(3, "SCORE_ANSWERS", "SCORE_ANSWERS");
  static readonly SCORE_ANSWERS_EXPLANATION = new FormationSessionQuizzResultMode(4, "SCORE_ANSWERS_EXPLANATION", "SCORE_ANSWERS_EXPLANATION");

  static readonly values = [
    FormationSessionQuizzResultMode.NO_RESULT,
 
    FormationSessionQuizzResultMode.SCORE,
 
    FormationSessionQuizzResultMode.SCORE_ANSWERS,
 
    FormationSessionQuizzResultMode.SCORE_ANSWERS_EXPLANATION
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of FormationSessionQuizzResultMode.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of FormationSessionQuizzResultMode.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.mif.entities.session.FormationSessionQuizzResultMode'] = FormationSessionQuizzResultMode;
reverseMapping[FormationSessionQuizzResultMode.name] = 'fr.hiji.metier.mif.entities.session.FormationSessionQuizzResultMode';
