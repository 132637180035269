// fr.hiji.metier.translate.services.ImportExportTranslationBOServiceI generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:49 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';

@Injectable({
  providedIn: 'root',
})
export class ImportExportTranslationBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  exportCampaign180Translations(idCampaign: number, langues: string[]): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ImportExportTranslationBOService.exportCampaign180Translations';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(langues, 'java.util.List<java.lang.String>', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  exportCertificationTranslations(idCertfication: number, langues: string[]): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ImportExportTranslationBOService.exportCertificationTranslations';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCertfication, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(langues, 'java.util.List<java.lang.String>', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  importCampaign180Translations(): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ImportExportTranslationBOService.importCampaign180Translations';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  importCertificationTranslations(): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ImportExportTranslationBOService.importCertificationTranslations';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
