// ENTITY fr.hiji.metier.campaign.entities._180.Campaign180Infos generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class Campaign180Infos {

  idCampaign: number | null = 0;
  code: string | null = '';

  constructor(init?:Partial<Campaign180Infos>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.entities._180.Campaign180Infos'] = Campaign180Infos;

reverseMapping[Campaign180Infos.name] = 'fr.hiji.metier.campaign.entities._180.Campaign180Infos';

fields['fr.hiji.metier.campaign.entities._180.Campaign180Infos']  = {
    idCampaign: 'java.lang.Integer',
    code: 'java.lang.String'
};
