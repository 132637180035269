// ENTITY fr.hiji.metier.tools.email.Email generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { HijiEmail } from './HijiEmail';

export class Email {

  emailType: HijiEmail | null = null;
  slug: string | null = '';
  senderEmail: string | null = '';
  senderName: MultilingualString | null = new MultilingualString();
  subject: MultilingualString | null = new MultilingualString();
  content: MultilingualString | null = new MultilingualString();
  idUserUpdater: number | null = 0;
  updateDate: Date | null = null;
  version: number | null = 0;
  customized: boolean | null = false;

  constructor(init?:Partial<Email>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.tools.email.Email'] = Email;

reverseMapping[Email.name] = 'fr.hiji.metier.tools.email.Email';

fields['fr.hiji.metier.tools.email.Email']  = {
    emailType: 'fr.hiji.metier.tools.email.HijiEmail',
    slug: 'java.lang.String',
    senderEmail: 'java.lang.String',
    senderName: 'com.ic2.sc.MultilingualString',
    subject: 'com.ic2.sc.MultilingualString',
    content: 'com.ic2.sc.MultilingualString',
    idUserUpdater: 'java.lang.Integer',
    updateDate: 'java.time.Instant',
    version: 'java.lang.Integer',
    customized: 'java.lang.Boolean'
};
