<!-- <div class="modal-header">
  <h5 class="modal-title"></h5>
  <button class="btn btn-close" type="button" [disabled]="loading" data-dismiss="modal" (click)="modal.dismiss()" aria-label="Close">
    <i class="fa-light fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <div class="container-fluid">body</div>
</div>
<div class="modal-footer">
  <button class="btn btn-dark" type="button" data-dismiss="modal" [disabled]="loading" (click)="modal.dismiss()">
    {{ 'global.Annuler' | translate }}
  </button>
  <button class="btn btn-primary" type="button" (click)="modal.close()">
    {{ 'global.Valider' | translate }}
    <i class="fa-light fa-spinner spinning ms-2" *ngIf="loading"></i>
  </button>
</div> -->
