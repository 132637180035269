import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CriteriaType } from '../../../ic2/entities/CriteriaType';
import { Tag } from '../../../ic2/entities/Tag';
import { TagManager } from '../../../services/tag-manager.service';
import { RadarData, RadarValue } from '../radar-chart/radar-chart.component';
export interface TagRadarValue extends RadarValue {
  axis: string;
  value: number;
  color: string;
  /** needs to be the idTag */ data: number;
  tooltip?: string;
}
export interface TagRadarData extends RadarData {
  values: TagRadarValue[];
}
@Component({
  selector: 'hiji-tag-radar-chart',
  templateUrl: './tag-radar-chart.component.html',
  styleUrls: ['./tag-radar-chart.component.scss'],
})
export class TagRadarChartComponent implements OnInit, OnChanges {
  @Input()
  data: TagRadarData[] = [];
  @Input()
  criteriaType: CriteriaType = null;
  reworkedData: RadarData[] = [];
  showChildrenOf: number = null;
  @Input()
  showAllSkills: boolean = false;

  constructor(private tagManager: TagManager) {}

  ngOnInit(): void {
    this.buildReworkedData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) this.buildReworkedData();
  }

  radarTitleClick(data) {
    //console.log('radar title clic', data);
    if (this.tagManager.getSonsOf(data, false).length > 0) {
      this.showChildrenOf = data;
      this.buildReworkedData();
    }
  }
  hasAnyTagsToShow() {
    return this.reworkedData.some((data) => data.values.length > 0);
  }

  canBeExpanded(data) {
    return this.tagManager.getSonsOf(data, false).length > 0;
  }

  resetShowChildren() {
    this.showChildrenOf = null;
    this.buildReworkedData();
  }

  getCurrentTag() {
    return this.tagManager.getTag(this.showChildrenOf);
  }

  buildReworkedData() {
    const showTag = (tag: Tag) =>
      (this.showAllSkills && this.showChildrenOf === null) ||
      (tag.showInRadar && this.showChildrenOf === null) ||
      this.showChildrenOf === this.tagManager.getTag(tag.idTag).idParent;

    this.reworkedData = this.data.map((data) => {
      return {
        name: data.name,
        color: data.color,
        background: data.background,
        values: data.values.filter((value) => showTag(this.tagManager.getTag(value.data))),
      };
    });

    const nbRootSkillsMinimumToForceShownParents = 2;

    if (
      this.reworkedData.length > 0 &&
      this.reworkedData[0].values.length < nbRootSkillsMinimumToForceShownParents &&
      this.data.length > 0 &&
      this.data[0].values.length >= nbRootSkillsMinimumToForceShownParents
    ) {
      //console.log('SHOULD SHOW MORE');
      this.reworkedData = this.data.map((data) => {
        //Find tags wihout children in our data
        let tags = data.values.map((value) => this.tagManager.getTag(value.data));
        tags = tags.filter((tag) => {
          return !tags.some((t) => t.idParent === tag.idTag);
        });
        //console.log(tags);

        const tagsWithoutChildren = data.values.filter((value) => {
          return tags.some((t) => t.idTag === value.data);
        });
        return {
          name: data.name,
          color: data.color,
          background: data.background,
          values: tagsWithoutChildren,
        };
      });
    }

    //console.log('reworkedData', this.reworkedData);
  }
}
