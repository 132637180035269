// ENTITY fr.hiji.metier.mif.entities.trainingpath.TrainingPathFormation generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class TrainingPathFormation {

  idTrainingPath: number | null = 0;
  idFormation: number | null = 0;
  order: number | null = 0;

  constructor(init?:Partial<TrainingPathFormation>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.trainingpath.TrainingPathFormation'] = TrainingPathFormation;

reverseMapping[TrainingPathFormation.name] = 'fr.hiji.metier.mif.entities.trainingpath.TrainingPathFormation';

fields['fr.hiji.metier.mif.entities.trainingpath.TrainingPathFormation']  = {
    idTrainingPath: 'java.lang.Integer',
    idFormation: 'java.lang.Integer',
    order: 'java.lang.Integer'
};
