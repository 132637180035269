// ENTITY fr.hiji.metier.certif.dto.front.modality.roleplay.ModalityRoleplayAnswerDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModalityRoleplayAnswerDTO {

  idModalityRoleplayAnswer: number | null = 0;
  idUserObserver: number | null = 0;
  observerName: string | null = '';
  draft: boolean | null = false;

  constructor(init?:Partial<ModalityRoleplayAnswerDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.front.modality.roleplay.ModalityRoleplayAnswerDTO'] = ModalityRoleplayAnswerDTO;

reverseMapping[ModalityRoleplayAnswerDTO.name] = 'fr.hiji.metier.certif.dto.front.modality.roleplay.ModalityRoleplayAnswerDTO';

fields['fr.hiji.metier.certif.dto.front.modality.roleplay.ModalityRoleplayAnswerDTO']  = {
    idModalityRoleplayAnswer: 'java.lang.Integer',
    idUserObserver: 'java.lang.Integer',
    observerName: 'java.lang.String',
    draft: 'java.lang.Boolean'
};
