// Facade exporting all classes for simple loading
// use export * from "./entities/entities"; in your module file

// Classes:
export * from './AcquisitionMIFStatsBODTO';
export * from './ActivationMIFStatsBODTO';
export * from './AssessorTeamsHHEGDTO';
export * from './AttendanceDTO';
export * from './AttributBODTO';
export * from './BadgesDTO';
export * from './Bilan';
export * from './BilanBODTO';
export * from './BusinessMIFStatsBODTO';
export * from './Campaign';
export * from './Campaign180Infos';
export * from './Campaign360BODTO';
export * from './Campaign360Infos';
export * from './Campaign360ObservableGesture';
export * from './Campaign360ObservableGestureBODTO';
export * from './Campaign360Relance';
export * from './Campaign360SkillTarget';
export * from './CampaignBODTO';
export * from './CampaignEmail';
export * from './CampaignItemBODTO';
export * from './CampaignLiteDTO';
export * from './CampaignPDIFormation';
export * from './CampaignPDIFormationBODTO';
export * from './CampaignPDIInfos';
export * from './CampaignPDIInfosBODTO';
export * from './CampaignPDIUser';
export * from './CampaignPDIUserBODTO';
export * from './CampaignPDIUserFormation';
export * from './CampaignPDIUserFormationResultBODTO';
export * from './CampaignPDIUserResponsable';
export * from './CampaignPDIUserResponsableBODTO';
export * from './CampaignPodium';
export * from './CampaignRepositoryAccessDTO';
export * from './CampaignRepositoryBODTO';
export * from './CampaignRepositoryPopulation';
export * from './CampaignRepositoryPopulationBODTO';
export * from './CampaignStatsDTO';
export * from './CampaignStatsFilterBODTO';
export * from './CampaignSurveyInfos';
export * from './CampaignUserAccess';
export * from './CampaignUserAccessBODTO';
export * from './Certification';
export * from './CertificationBODTO';
export * from './CertificationCandidateResult';
export * from './CertificationExam';
export * from './CertificationExamResultDTO';
export * from './CertificationLiteDTO';
export * from './CertificationQuestionStatsBODTO';
export * from './CertificationQuizOpenQuestionBODTO';
export * from './CertificationQuizQCMQuestionAnswerBODTO';
export * from './CertificationQuizQuestionQCMAnswerDistributionBODTO';
export * from './CertificationQuizStatsBODTO';
export * from './CertificationResultDTO';
export * from './CertificationSession';
export * from './CertificationSessionAssessor';
export * from './CertificationSessionBODTO';
export * from './CertificationSessionCandidate';
export * from './CertificationSessionCandidateDTO';
export * from './CertificationSessionCandidatesDTO';
export * from './CertificationSessionDTO';
export * from './CertificationSessionExamBODTO';
export * from './CertificationSessionLiteBODTO';
export * from './CertificationSessionLiteDTO';
export * from './CertificationSessionModality';
export * from './CertificationSessionModalityBODTO';
export * from './CertificationSessionStatsLiteDTO';
export * from './CertificationSessionsDTO';
export * from './CertificationStatsFiltersBODTO';
export * from './CertificationStatsInfoBODTO';
export * from './CertificationStatsModalityBODTO';
export * from './CertificationStatsModalityInfoBODTO';
export * from './CertificationStatsObersavableGestureBODTO';
export * from './CertificationStatsTagBODTO';
export * from './CertificationStatsTagValueBODTO';
export * from './CertificationVerbatimStatsBODTO';
export * from './CertificationWithSessionsDTO';
export * from './CritereTauxEchange';
export * from './Criteria';
export * from './CriteriaBODTO';
export * from './DashboardBODTO';
export * from './DashboardCampaignBODTO';
export * from './DashboardCertificationBODTO';
export * from './DashboardFormationBODTO';
export * from './DataTranslatorBODTO';
export * from './DiffusionList';
export * from './DiffusionListBODTO';
export * from './EMA';
export * from './EMACustomOGSkillStatBODTO';
export * from './EMACustomStatsBODTO';
export * from './EMADTO';
export * from './EMAEtablissementStatsBODTO';
export * from './EMAHasRepository';
export * from './EMALiteCoachDTO';
export * from './EMALiteDTO';
export * from './EMAManagerStatDTO';
export * from './EMANextRepository';
export * from './EMAOGHistogramDTO';
export * from './EMAOGRepositoryLink';
export * from './EMAObjective';
export * from './EMAObjectiveDTO';
export * from './EMAObjectiveDefinition';
export * from './EMAObjectiveLiteDTO';
export * from './EMAObjectiveResult';
export * from './EMAObjectiveResultBODTO';
export * from './EMAObservableGestureBODTO';
export * from './EMAObservableGestureStatDTO';
export * from './EMAObservation';
export * from './EMAObservationDTO';
export * from './EMAObservationOGCDTO';
export * from './EMAObservationObservableGestures';
export * from './EMAObservationObservableGesturesDTO';
export * from './EMAParticipationStatsDTO';
export * from './EMAPerception';
export * from './EMAPerceptionStatsBODTO';
export * from './EMARDV';
export * from './EMARepositoryLiteDTO';
export * from './EMARepositoryStatsBODTO';
export * from './EMASkillMessage';
export * from './EMASkillMessageDTO';
export * from './EMASkillMessageWithUserDTO';
export * from './EMASkillStatBODTO';
export * from './EMAStatsFilterBODTO';
export * from './EMATeamMemberObjectiveResultDTO';
export * from './EMATeamObjectiveDTO';
export * from './EMATeamResultDTO';
export * from './EMATemplate';
export * from './EMATemplateBODTO';
export * from './EMATemplateEnabledStep';
export * from './EMATemplateItemBODTO';
export * from './EMATemplateRepository';
export * from './EMATemplateRepositoryBODTO';
export * from './EMAUserOG';
export * from './EMAUserOGC';
export * from './EMAUserOGCDTO';
export * from './EMAUserOGDTO';
export * from './EVA';
export * from './EVADTO';
export * from './EVAQuizOpenEndedAnswer';
export * from './EVAQuizQCMAnswer';
export * from './Email';
export * from './EmailBODTO';
export * from './EmailPreviewDTO';
export * from './EtablissementStatsBODTO';
export * from './ExamLiteDTO';
export * from './ExchangeDTO';
export * from './ExchangeDetailDTO';
export * from './ExchangeItemDTO';
export * from './ExchangeManagerSelectionDTO';
export * from './ExchangeObservationDTO';
export * from './ExchangeObservationObservableGestureDTO';
export * from './ExchangePerWeekDTO';
export * from './ExchangeRepositorySelectionDTO';
export * from './Feedback';
export * from './FeedbackCategory';
export * from './FeedbackCategoryStatBODTO';
export * from './FeedbackDTO';
export * from './FeedbackPerAreaBODTO';
export * from './FeedbackPerMonthBODTO';
export * from './FeedbackReactionDTO';
export * from './FeedbackSolicitationDTO';
export * from './FeedbackStatBODTO';
export * from './FeedbackUserDTO';
export * from './FeedbackUserReactionDTO';
export * from './FileToken';
export * from './Fonction';
export * from './FonctionBODTO';
export * from './FonctionItemBODTO';
export * from './FonctionLiteDTO';
export * from './Formation';
export * from './FormationBODTO';
export * from './FormationEVADTO';
export * from './FormationEmail';
export * from './FormationEvent';
export * from './FormationEventAcquisitionDTO';
export * from './FormationEventAcquisitionOGDTO';
export * from './FormationEventAcquisitionStatsBODTO';
export * from './FormationEventAnimation';
export * from './FormationEventAnimationBODTO';
export * from './FormationEventAnimationDTO';
export * from './FormationEventAnimationOG';
export * from './FormationEventAnimationOGSDTO';
export * from './FormationEventAnimationParticipation';
export * from './FormationEventAnimationSatisfactionLiteBODTO';
export * from './FormationEventAnimationTrainer';
export * from './FormationEventAnimationTrainerBODTO';
export * from './FormationEventBODTO';
export * from './FormationEventDTO';
export * from './FormationEventDeployDTO';
export * from './FormationEventDiffusionList';
export * from './FormationEventDiffusionListBODTO';
export * from './FormationEventItemBODTO';
export * from './FormationEventLiteDTO';
export * from './FormationEventObservableGestureStatsBODTO';
export * from './FormationEventObservation';
export * from './FormationEventObservationObservableGesture';
export * from './FormationEventParticipant';
export * from './FormationEventParticipantBODTO';
export * from './FormationEventParticipantImportBODTO';
export * from './FormationEventSatisfactionBODTO';
export * from './FormationEventSatisfactionDTO';
export * from './FormationEventSatisfactionOGDTO';
export * from './FormationEventSatisfactionOgsOrderDTO';
export * from './FormationEventSkillStatsBODTO';
export * from './FormationEventStatsFiltersBODTO';
export * from './FormationEventStatsInfoBODTO';
export * from './FormationEventTag';
export * from './FormationEventVerbatimStatsBODTO';
export * from './FormationExpectationStatsBODTO';
export * from './FormationFile';
export * from './FormationFileBODTO';
export * from './FormationLiteDTO';
export * from './FormationObservableGestureStatsBODTO';
export * from './FormationObservation';
export * from './FormationObservationObservableGesture';
export * from './FormationObservationObservableGestureDTO';
export * from './FormationQuiz';
export * from './FormationQuizAnswer';
export * from './FormationQuizAnswerSkillAvg';
export * from './FormationQuizBODTO';
export * from './FormationQuizItemStatsBODTO';
export * from './FormationQuizLiteDTO';
export * from './FormationQuizOpenEndedAnswer';
export * from './FormationQuizQCMAnswer';
export * from './FormationQuizQuestionOuverteStatsBODTO';
export * from './FormationQuizQuestionQCMAnswerDistributionBODTO';
export * from './FormationQuizQuestionStatsBODTO';
export * from './FormationSession';
export * from './FormationSessionAcquisitionDTO';
export * from './FormationSessionActionDTO';
export * from './FormationSessionBODTO';
export * from './FormationSessionDTO';
export * from './FormationSessionDate';
export * from './FormationSessionDiffusionList';
export * from './FormationSessionDiffusionListBODTO';
export * from './FormationSessionEmailConfig';
export * from './FormationSessionExpectationsManagerDTO';
export * from './FormationSessionExpectationsResultDTO';
export * from './FormationSessionLiteBODTO';
export * from './FormationSessionLiteDTO';
export * from './FormationSessionParticipant';
export * from './FormationSessionParticipantAction';
export * from './FormationSessionParticipantAttendance';
export * from './FormationSessionParticipantAttendanceSignLiteBODTO';
export * from './FormationSessionParticipantBODTO';
export * from './FormationSessionParticipantDTO';
export * from './FormationSessionParticipantExpectation';
export * from './FormationSessionParticipantExpectationsDTO';
export * from './FormationSessionParticipantObservationsActionsDTO';
export * from './FormationSessionQuizAvailable';
export * from './FormationSessionStatsLiteDTO';
export * from './FormationSessionTrainer';
export * from './FormationSessionTrainerActionsDTO';
export * from './FormationSessionTrainerBODTO';
export * from './FormationSessionTrainerDTO';
export * from './FormationSkillStatsBODTO';
export * from './FormationStatsFiltersBODTO';
export * from './FormationStatsInfoBODTO';
export * from './FormationTag';
export * from './FormationTrainer';
export * from './FormationTrainerBODTO';
export * from './FormationVerbatimStatsBODTO';
export * from './GroupDTO';
export * from './HijiBundle';
export * from './HijiFile';
export * from './HijiFileDTO';
export * from './HijiUser';
export * from './HijiUserReportEmaSubscriptionBODTO';
export * from './HijiUserReportSubscriptionBODTO';
export * from './HistogramDTO';
export * from './HomeDTO';
export * from './ImportResultDTO';
export * from './Inquiry';
export * from './InquiryActionPlan';
export * from './InquiryAnswerDTO';
export * from './InquiryCreateDTO';
export * from './InquiryDTO';
export * from './InquiryLiteBODTO';
export * from './InquiryObservableGestureDTO';
export * from './InquiryObservationDTO';
export * from './InquirySkillStatDTO';
export * from './InquiryTarget';
export * from './InquiryTargetDTO';
export * from './InquiryTargetMessages';
export * from './InquiryTargetOOGDTO';
export * from './InterviewStatsBODTO';
export * from './InterviewStatsQuickQuestionBODTO';
export * from './JoinResultDTO';
export * from './KeyGestureStatDTO';
export * from './KpiDTO';
export * from './LayoutManagerDTO';
export * from './LayoutTrainerDTO';
export * from './LoginResponse';
export * from './ManagerInvitationDTO';
export * from './ManagerStatDTO';
export * from './MenuManagerDTO';
export * from './MenuTrainerDTO';
export * from './ModalitiesDTO';
export * from './Modality';
export * from './ModalityBODTO';
export * from './ModalityDTO';
export * from './ModalityInstructionDTO';
export * from './ModalityInterview';
export * from './ModalityInterviewBODTO';
export * from './ModalityInterviewDTO';
export * from './ModalityInterviewExam';
export * from './ModalityInterviewExamOG';
export * from './ModalityInterviewExamSkillAvg';
export * from './ModalityInterviewOGPerTag';
export * from './ModalityInterviewQuickQuestion';
export * from './ModalityInterviewResultDTO';
export * from './ModalityInterviewTarget';
export * from './ModalityListStatsBODTO';
export * from './ModalityLiteBODTO';
export * from './ModalityLiteDTO';
export * from './ModalityQuiz';
export * from './ModalityQuizBODTO';
export * from './ModalityQuizDTO';
export * from './ModalityQuizExam';
export * from './ModalityQuizExamSkillAvg';
export * from './ModalityQuizQuestionDTO';
export * from './ModalityQuizQuestionOpenEndedAnswer';
export * from './ModalityQuizQuestionQCMAnswer';
export * from './ModalityQuizQuestionResultDTO';
export * from './ModalityQuizQuestionsPerTag';
export * from './ModalityQuizResultDTO';
export * from './ModalityQuizTarget';
export * from './ModalityRoleplay';
export * from './ModalityRoleplayAnswerDTO';
export * from './ModalityRoleplayAnswerOG';
export * from './ModalityRoleplayAnswerSkillAvg';
export * from './ModalityRoleplayBODTO';
export * from './ModalityRoleplayDTO';
export * from './ModalityRoleplayExam';
export * from './ModalityRoleplayResultDTO';
export * from './ModalityRoleplayTarget';
export * from './Module180DTO';
export * from './Module360DTO';
export * from './ModuleBilanDTO';
export * from './ModuleCertificationDTO';
export * from './ModuleEMADTO';
export * from './ModuleEventDTO';
export * from './ModuleFBSDTO';
export * from './ModuleGlobalDTO';
export * from './ModuleMesureImpactFormationDTO';
export * from './ModulePDIDTO';
export * from './ModuleSurveyDTO';
export * from './ModuleTMSDTO';
export * from './ModuleTeamBoardDTO';
export * from './ModulesBODTO';
export * from './ModulesDTO';
export * from './NbAnswerPerOpinionTypeBODTO';
export * from './NbAnswerPerOpinionTypePerAnswerValBODTO';
export * from './NbExchangePerRepositoryDTO';
export * from './NbOgsPerTagDTO';
export * from './OGStatPerObservationTypeBODTO';
export * from './OGStatsPerFormationEventObservationTypeBODTO';
export * from './OGStatsPerFormationObservationTypeBODTO';
export * from './OGVerbatimDTO';
export * from './OOGCategoriesDTO';
export * from './ObservableGesture';
export * from './ObservableGestureBODTO';
export * from './ObservableGestureChartValueDTO';
export * from './ObservableGestureDTO';
export * from './ObservableGestureFlatTag';
export * from './ObservableGestureLiteBODTO';
export * from './ObservableGestureLiteDTO';
export * from './ObservableGestureStatDTO';
export * from './ObservationDTO';
export * from './ObservationObservableGestures';
export * from './ObservationObservableGesturesDTO';
export * from './OpinionAnswer';
export * from './OpinionDTO';
export * from './OpinionQuestion';
export * from './OpinionQuestionBODTO';
export * from './OpinionSuggestion';
export * from './OpinionSuggestionBODTO';
export * from './Organisation';
export * from './OrganisationBODTO';
export * from './PDFInfoDTO';
export * from './PDIDTO';
export * from './PDIFormationDTO';
export * from './PDILiteBODTO';
export * from './PDIOGDTO';
export * from './PDIObsDTO';
export * from './PDIObservationDTO';
export * from './PDIResultDTO';
export * from './PPTFilterConfigMIFBODTO';
export * from './ParticipantAttendanceDTO';
export * from './ParticipantPerDayStatBODTO';
export * from './ParticipantQuizResults';
export * from './ParticipantResultsDTO';
export * from './ParticipationStatsBySurveyBODTO';
export * from './PodiumOrderedGesteObservable';
export * from './Population';
export * from './PopulationBODTO';
export * from './PopulationFilter';
export * from './PopulationFilterBODTO';
export * from './PopulationItemBODTO';
export * from './Question';
export * from './QuestionFlatTag';
export * from './QuestionQCM';
export * from './QuestionQCMAnswer';
export * from './QuestionQCMAnswerDTO';
export * from './QuestionQCMBODTO';
export * from './Quiz';
export * from './QuizBODTO';
export * from './QuizDTO';
export * from './QuizDataStatsMIFDTO';
export * from './QuizEVADTO';
export * from './QuizItemBODTO';
export * from './QuizMIFDTO';
export * from './QuizQuestion';
export * from './QuizQuestionBODTO';
export * from './QuizResultMIFDTO';
export * from './QuizResultsTrainerMIFDTO';
export * from './Registration';
export * from './RelanceInfoBODTO';
export * from './Report180Config';
export * from './Report180ConfigBODTO';
export * from './Report180ConfigCampaign';
export * from './Report180ConfigCampaignBODTO';
export * from './Report180ConfigDepartment';
export * from './Report180ConfigDepartmentBODTO';
export * from './Report180ConfigLiteBODTO';
export * from './Report180ExchangeRate';
export * from './ReportDTO';
export * from './ReportEMAConfig';
export * from './ReportEMAConfigBODTO';
export * from './ReportEMAConfigLiteBODTO';
export * from './ReportEMAConfigTemplate';
export * from './ReportEMAConfigTemplateBODTO';
export * from './ReportEmaDate';
export * from './Repository';
export * from './RepositoryAccessDTO';
export * from './RepositoryBODTO';
export * from './RepositoryHasObservableGesture';
export * from './RepositoryItemBODTO';
export * from './RepositoryLiteDTO';
export * from './RepositoryObservableGestureBODTO';
export * from './RepositoryResultDTO';
export * from './RoleplayStatsBODTO';
export * from './SSOConfigDTO';
export * from './SatisfactionMIFStatsBODTO';
export * from './SatisfactionRepositoryAnswerDTO';
export * from './ServicePercentDTO';
export * from './SkillStatBODTO';
export * from './SkillStatDTO';
export * from './SkillStatPerObservationTypeBODTO';
export * from './SollicitationPerTargetTypeBODTO';
export * from './StaticPage';
export * from './StaticPageBODTO';
export * from './SurveyBODTO';
export * from './SurveyDTO';
export * from './SurveyItemDTO';
export * from './SurveyLiteBODTO';
export * from './SurveyObservableGestureDTO';
export * from './SurveyParticipationReportingUserBODTO';
export * from './SurveyRelance';
export * from './SurveyReport';
export * from './SurveyReportConfigBODTO';
export * from './SurveyReportDTO';
export * from './SurveyReportFilter';
export * from './SurveyReportGlobalDataDTO';
export * from './SurveyReportIndiceEngagement';
export * from './SurveyReportManager';
export * from './SurveyReportObservableGesture';
export * from './SurveyReportObservableGestureDTO';
export * from './SurveyReportOrganisation';
export * from './SurveyReportSkillDataDTO';
export * from './SurveySkillStatDTO';
export * from './SurveyStatsParticipationByOrganisationBODTO';
export * from './SurveyStatsParticipationPerDayBODTO';
export * from './SurveyStatsScoreByManagerBODTO';
export * from './SurveyStatsScoreByOrganisationBODTO';
export * from './SurveyUser';
export * from './SurveyUserBODTO';
export * from './SurveyVisualizeDTO';
export * from './Tag';
export * from './TagBODTO';
export * from './TeamBoard';
export * from './TeamBoardBrief';
export * from './TeamBoardBriefDTO';
export * from './TeamBoardDTO';
export * from './TeamBoardLiteDTO';
export * from './TeamMemberDTO';
export * from './TeamMemberEMARecapDTO';
export * from './TrainingPath';
export * from './TrainingPathBODTO';
export * from './TrainingPathFormation';
export * from './TrainingPathFormationBODTO';
export * from './TrainingPathFormationRequirement';
export * from './TrainingPathFormationRequirementBODTO';
export * from './TrainingPathItemBODTO';
export * from './User';
export * from './UserActionsDTO';
export * from './UserActionsEMADTO';
export * from './UserBODTO';
export * from './UserBundle';
export * from './UserConfig';
export * from './UserEMALiteBODTO';
export * from './UserExchangeLiteBODTO';
export * from './UserFonctionPerimeterConfig';
export * from './UserFuncAvatarDTO';
export * from './UserImageToken';
export * from './UserInquiryBODTO';
export * from './UserItemBODTO';
export * from './UserLiteDTO';
export * from './UserProfileDTO';
export * from './UserRegisterDTO';
export * from './UserRelation';
export * from './UserRelationBODTO';
export * from './UserRight';
export * from './UserSawTeamBoard';
export * from './UserSawTeamBoardBrief';
export * from './VerbatimCategory';
export * from './VisualizeDTO';
export * from './VizBilanDTO';
export * from './VizInquiryAnswerDTO';
export * from './VizInquiryDTO';
export * from './VizSurveyDTO';


// Enums:
export * from './AgeRange';
export * from './CampaignType';
export * from './CriteriaType';
export * from './EMAOGAddReason';
export * from './EMAOGRemoveReason';
export * from './EMAObjectiveFormula';
export * from './EMAObjectiveType';
export * from './EMAPerceptionType';
export * from './EMAStep';
export * from './EMAType';
export * from './EVAStep';
export * from './EmailAvailability';
export * from './FeedbackReaction';
export * from './FeedbackRecipientType';
export * from './FormationEventAnimationType';
export * from './FormationEventObservationType';
export * from './FormationObservationType';
export * from './FormationSessionDatePeriod';
export * from './FormationSessionQuizzResultMode';
export * from './FormationSessionWorkflowStep';
export * from './FrequenceRapport';
export * from './Genre';
export * from './GroupType';
export * from './HighlightType';
export * from './HijiEmail';
export * from './HijiRelationUser';
export * from './HijiRight';
export * from './InquiryTargetType';
export * from './ModalityType';
export * from './ObservationType';
export * from './OpinionType';
export * from './OrganisationType';
export * from './PodiumType';
export * from './QuestionType';
export * from './RegistrationStatus';
export * from './SeniorityRange';
export * from './SurveyReportGenerationType';
