// ENTITY fr.hiji.metier.certif.dto.front.modality.ModalityDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:57 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';
import { ModalityType } from './ModalityType';

export class ModalityDTO {

  idCertificationSession: number | null = 0;
  certificationName: MultilingualString | null = new MultilingualString();
  sessionName: string | null = '';
  user: UserFuncAvatarDTO | null = null;
  idModality: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  type: ModalityType | null = null;

  constructor(init?:Partial<ModalityDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.front.modality.ModalityDTO'] = ModalityDTO;

reverseMapping[ModalityDTO.name] = 'fr.hiji.metier.certif.dto.front.modality.ModalityDTO';

fields['fr.hiji.metier.certif.dto.front.modality.ModalityDTO']  = {
    idCertificationSession: 'java.lang.Integer',
    certificationName: 'com.ic2.sc.MultilingualString',
    sessionName: 'java.lang.String',
    user: 'fr.hiji.metier.user.dto.front.UserFuncAvatarDTO',
    idModality: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    type: 'fr.hiji.metier.certif.entities.modality.ModalityType'
};
