// ENTITY fr.hiji.metier.certif.dto.back.stats.quiz.CertificationQuizQuestionQCMAnswerDistributionBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:59 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class CertificationQuizQuestionQCMAnswerDistributionBODTO {

  distribution: number | null = 0;
  nbQuestionAnswered: number | null = 0;
  idQuestion: number | null = 0;
  question: MultilingualString | null = new MultilingualString();
  questionImage: MultilingualString | null = new MultilingualString();
  answer: MultilingualString | null = new MultilingualString();
  answerImage: MultilingualString | null = new MultilingualString();
  isGoodAnswer: boolean | null = false;

  constructor(init?:Partial<CertificationQuizQuestionQCMAnswerDistributionBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.back.stats.quiz.CertificationQuizQuestionQCMAnswerDistributionBODTO'] = CertificationQuizQuestionQCMAnswerDistributionBODTO;

reverseMapping[CertificationQuizQuestionQCMAnswerDistributionBODTO.name] = 'fr.hiji.metier.certif.dto.back.stats.quiz.CertificationQuizQuestionQCMAnswerDistributionBODTO';

fields['fr.hiji.metier.certif.dto.back.stats.quiz.CertificationQuizQuestionQCMAnswerDistributionBODTO']  = {
    distribution: 'java.lang.Integer',
    nbQuestionAnswered: 'java.lang.Integer',
    idQuestion: 'java.lang.Integer',
    question: 'com.ic2.sc.MultilingualString',
    questionImage: 'com.ic2.sc.MultilingualString',
    answer: 'com.ic2.sc.MultilingualString',
    answerImage: 'com.ic2.sc.MultilingualString',
    isGoodAnswer: 'java.lang.Boolean'
};
