// ENTITY fr.hiji.metier.mif.entities.event.observation.FormationEventObservationObservableGesture generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:59 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class FormationEventObservationObservableGesture {

  idFormationEventObservation: number | null = 0;
  idObservableGesture: number | null = 0;
  idFormationEventAnimation: number | null = null;
  value: number | null = 0;
  observed: boolean | null = false;
  answerDate: Date | null = null;
  text: string | null = '';

  constructor(init?:Partial<FormationEventObservationObservableGesture>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.event.observation.FormationEventObservationObservableGesture'] = FormationEventObservationObservableGesture;

reverseMapping[FormationEventObservationObservableGesture.name] = 'fr.hiji.metier.mif.entities.event.observation.FormationEventObservationObservableGesture';

fields['fr.hiji.metier.mif.entities.event.observation.FormationEventObservationObservableGesture']  = {
    idFormationEventObservation: 'java.lang.Integer',
    idObservableGesture: 'java.lang.Integer',
    idFormationEventAnimation: 'java.lang.Integer',
    value: 'java.lang.Integer',
    observed: 'java.lang.Boolean',
    answerDate: 'java.time.Instant',
    text: 'java.lang.String'
};
