// ENTITY fr.hiji.metier.ema.entities.objectif.EMAObjective generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { EMAObjectiveFormula } from './EMAObjectiveFormula';
import { EMAObjectiveType } from './EMAObjectiveType';

export class EMAObjective {

  idEMAObjective: number | null = 0;
  idEMAObjectiveParent: number | null = null;
  type: EMAObjectiveType | null = null;
  idEMATemplate: number | null = null;
  idUser: number | null = null;
  name: MultilingualString | null = new MultilingualString();
  unit: MultilingualString | null = new MultilingualString();
  automaticDefinition: boolean | null = false;
  automaticResult: boolean | null = false;
  shownInProfile: boolean | null = false;
  showAsPercentageInGraph: boolean | null = false;
  formula: EMAObjectiveFormula | null = null;
  data: string | null = '';
  creationDate: Date | null = null;
  idUserCreator: number | null = 0;
  idEMACreation: number | null = null;
  idEMADeleted: number | null = null;
  idUserDeleter: number | null = null;
  del: boolean | null = false;

  constructor(init?:Partial<EMAObjective>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.entities.objectif.EMAObjective'] = EMAObjective;

reverseMapping[EMAObjective.name] = 'fr.hiji.metier.ema.entities.objectif.EMAObjective';

fields['fr.hiji.metier.ema.entities.objectif.EMAObjective']  = {
    idEMAObjective: 'java.lang.Integer',
    idEMAObjectiveParent: 'java.lang.Integer',
    type: 'fr.hiji.metier.ema.entities.objectif.EMAObjectiveType',
    idEMATemplate: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    unit: 'com.ic2.sc.MultilingualString',
    automaticDefinition: 'java.lang.Boolean',
    automaticResult: 'java.lang.Boolean',
    shownInProfile: 'java.lang.Boolean',
    showAsPercentageInGraph: 'java.lang.Boolean',
    formula: 'fr.hiji.metier.ema.entities.objectif.EMAObjectiveFormula',
    data: 'java.lang.String',
    creationDate: 'java.time.Instant',
    idUserCreator: 'java.lang.Integer',
    idEMACreation: 'java.lang.Integer',
    idEMADeleted: 'java.lang.Integer',
    idUserDeleter: 'java.lang.Integer',
    del: 'java.lang.Boolean'
};
