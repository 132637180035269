// DB ENUM fr.hiji.metier.ema.entities.objectif.EMAObjectiveFormula generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:59 UTC 2024

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class EMAObjectiveFormula {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly RO = new EMAObjectiveFormula(1, "Résultat / Objectif", "RO");
  static readonly OR = new EMAObjectiveFormula(2, "Objectif / Résultat", "OR");

  static readonly values = [
    EMAObjectiveFormula.RO,
 
    EMAObjectiveFormula.OR
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of EMAObjectiveFormula.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of EMAObjectiveFormula.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.ema.entities.objectif.EMAObjectiveFormula'] = EMAObjectiveFormula;
reverseMapping[EMAObjectiveFormula.name] = 'fr.hiji.metier.ema.entities.objectif.EMAObjectiveFormula';
