// ENTITY fr.hiji.metier.ema.entities.EMAUserOGC generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:59 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class EMAUserOGC {

  idEMAUserOGC: number | null = 0;
  idUser: number | null = 0;
  question: string | null = '';
  idTag: number | null = 0;
  idCriteria: number | null = 0;
  addDate: Date | null = null;
  idUserCreator: number | null = 0;
  idEMACreation: number | null = 0;
  del: boolean | null = false;
  deleteDate: Date | null = null;
  idUserDelete: number | null = null;
  idEMADeleted: number | null = null;

  constructor(init?:Partial<EMAUserOGC>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.entities.EMAUserOGC'] = EMAUserOGC;

reverseMapping[EMAUserOGC.name] = 'fr.hiji.metier.ema.entities.EMAUserOGC';

fields['fr.hiji.metier.ema.entities.EMAUserOGC']  = {
    idEMAUserOGC: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    question: 'java.lang.String',
    idTag: 'java.lang.Integer',
    idCriteria: 'java.lang.Integer',
    addDate: 'java.time.Instant',
    idUserCreator: 'java.lang.Integer',
    idEMACreation: 'java.lang.Integer',
    del: 'java.lang.Boolean',
    deleteDate: 'java.time.Instant',
    idUserDelete: 'java.lang.Integer',
    idEMADeleted: 'java.lang.Integer'
};
