// DB ENUM fr.hiji.metier.ema.entities.EMAStep generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:59 UTC 2024

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class EMAStep {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly HOW_ARE_YOU_FEELING = new EMAStep(1, "Comment ça va ?", "HOW_ARE_YOU_FEELING");
  static readonly RESULTS_VS_OBJECTIVES = new EMAStep(10, "Résultats vs Objectifs", "RESULTS_VS_OBJECTIVES");
  static readonly FUTURE_OBJECTIVES = new EMAStep(20, "Objectifs futurs", "FUTURE_OBJECTIVES");
  static readonly HOW_DID_YOU_DO_IT_HOW_WILL_YOU_DO_IT = new EMAStep(30, "Observations", "HOW_DID_YOU_DO_IT_HOW_WILL_YOU_DO_IT");
  static readonly ENCOURAGING_WORDS = new EMAStep(50, "Synthèse", "ENCOURAGING_WORDS");
  static readonly REPOSITORY_SELECTION = new EMAStep(55, "Choix du référentiel", "REPOSITORY_SELECTION");
  static readonly PLAN_NEXT = new EMAStep(60, "Prochain RDV", "PLAN_NEXT");
  static readonly EMA_TYPE = new EMAStep(100, "Type", "EMA_TYPE");
  static readonly DONE = new EMAStep(200, "Done", "DONE");

  static readonly values = [
    EMAStep.HOW_ARE_YOU_FEELING,
 
    EMAStep.RESULTS_VS_OBJECTIVES,
 
    EMAStep.FUTURE_OBJECTIVES,
 
    EMAStep.HOW_DID_YOU_DO_IT_HOW_WILL_YOU_DO_IT,
 
    EMAStep.ENCOURAGING_WORDS,
 
    EMAStep.REPOSITORY_SELECTION,
 
    EMAStep.PLAN_NEXT,
 
    EMAStep.EMA_TYPE,
 
    EMAStep.DONE
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of EMAStep.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of EMAStep.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.ema.entities.EMAStep'] = EMAStep;
reverseMapping[EMAStep.name] = 'fr.hiji.metier.ema.entities.EMAStep';
