// ENTITY fr.hiji.metier.reseau.dto.PopulationItemBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { Population } from './Population';

export class PopulationItemBODTO {

  countUsers: number | null = 0;
  population: Population | null = null;

  constructor(init?:Partial<PopulationItemBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reseau.dto.PopulationItemBODTO'] = PopulationItemBODTO;

reverseMapping[PopulationItemBODTO.name] = 'fr.hiji.metier.reseau.dto.PopulationItemBODTO';

fields['fr.hiji.metier.reseau.dto.PopulationItemBODTO']  = {
    countUsers: 'java.lang.Integer',
    population: 'fr.hiji.metier.reseau.entities.Population'
};
