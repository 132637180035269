// ENTITY fr.hiji.metier.campaign.dto.back.stats.ParticipantPerDayStatBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ParticipantPerDayStatBODTO {

  date: Date | null = null;
  nbDeployed: number | null = 0;
  nbLaunched: number | null = 0;
  nbSollicite: number | null = 0;
  nbAnswers: number | null = 0;

  constructor(init?:Partial<ParticipantPerDayStatBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back.stats.ParticipantPerDayStatBODTO'] = ParticipantPerDayStatBODTO;

reverseMapping[ParticipantPerDayStatBODTO.name] = 'fr.hiji.metier.campaign.dto.back.stats.ParticipantPerDayStatBODTO';

fields['fr.hiji.metier.campaign.dto.back.stats.ParticipantPerDayStatBODTO']  = {
    date: 'java.time.LocalDate',
    nbDeployed: 'java.lang.Integer',
    nbLaunched: 'java.lang.Integer',
    nbSollicite: 'java.lang.Integer',
    nbAnswers: 'java.lang.Integer'
};
