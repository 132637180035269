// ENTITY fr.hiji.metier.campaign.entities._360.InquiryActionPlan generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:59 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class InquiryActionPlan {

  idInquiry: number | null = 0;
  idSkill: number | null = 0;
  objectif: string | null = '';
  action: string | null = '';
  delay: Date | null = null;
  status: number | null = 0;
  creationDate: Date | null = null;

  constructor(init?:Partial<InquiryActionPlan>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.entities._360.InquiryActionPlan'] = InquiryActionPlan;

reverseMapping[InquiryActionPlan.name] = 'fr.hiji.metier.campaign.entities._360.InquiryActionPlan';

fields['fr.hiji.metier.campaign.entities._360.InquiryActionPlan']  = {
    idInquiry: 'java.lang.Integer',
    idSkill: 'java.lang.Integer',
    objectif: 'java.lang.String',
    action: 'java.lang.String',
    delay: 'java.time.LocalDate',
    status: 'java.lang.Integer',
    creationDate: 'java.time.Instant'
};
