// ENTITY fr.hiji.metier.campaign.dto.front._360.InquiryObservableGestureDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:59 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { ObservableGestureFlatTag } from './ObservableGestureFlatTag';

export class InquiryObservableGestureDTO {

  idObservableGesture: number | null = 0;
  idCriteria: number | null = 0;
  order: number | null = 0;
  observableGestureName: MultilingualString | null = new MultilingualString();
  observableGestureDescription: MultilingualString | null = new MultilingualString();
  value: number | null = 0;
  text: string | null = '';
  observed: boolean | null = false;
  tags: ObservableGestureFlatTag[] | null = [];

  constructor(init?:Partial<InquiryObservableGestureDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.front._360.InquiryObservableGestureDTO'] = InquiryObservableGestureDTO;

reverseMapping[InquiryObservableGestureDTO.name] = 'fr.hiji.metier.campaign.dto.front._360.InquiryObservableGestureDTO';

fields['fr.hiji.metier.campaign.dto.front._360.InquiryObservableGestureDTO']  = {
    idObservableGesture: 'java.lang.Integer',
    idCriteria: 'java.lang.Integer',
    order: 'java.lang.Integer',
    observableGestureName: 'com.ic2.sc.MultilingualString',
    observableGestureDescription: 'com.ic2.sc.MultilingualString',
    value: 'java.lang.Integer',
    text: 'java.lang.String',
    observed: 'java.lang.Boolean',
    tags: 'java.util.List<fr.hiji.metier.referential.entities.ObservableGestureFlatTag>'
};
