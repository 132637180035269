// ENTITY fr.hiji.metier.mif.dto.front.manager.QuizEVADTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:57 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { EVAQuizQCMAnswer } from './EVAQuizQCMAnswer';
import { EVAQuizOpenEndedAnswer } from './EVAQuizOpenEndedAnswer';
import { ModalityQuizQuestionDTO } from './ModalityQuizQuestionDTO';

export class QuizEVADTO {

  idFormationSession: number | null = 0;
  idUserParticipant: number | null = 0;
  questions: ModalityQuizQuestionDTO[] | null = [];
  startAnsweringDate: Date | null = null;
  openEndedAnswers: EVAQuizOpenEndedAnswer[] | null = [];
  qcmSelectedAnswers: EVAQuizQCMAnswer[] | null = [];

  constructor(init?:Partial<QuizEVADTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.manager.QuizEVADTO'] = QuizEVADTO;

reverseMapping[QuizEVADTO.name] = 'fr.hiji.metier.mif.dto.front.manager.QuizEVADTO';

fields['fr.hiji.metier.mif.dto.front.manager.QuizEVADTO']  = {
    idFormationSession: 'java.lang.Integer',
    idUserParticipant: 'java.lang.Integer',
    questions: 'java.util.List<fr.hiji.metier.certif.dto.front.modality.quiz.ModalityQuizQuestionDTO>',
    startAnsweringDate: 'java.time.Instant',
    openEndedAnswers: 'java.util.List<fr.hiji.metier.mif.entities.eva.EVAQuizOpenEndedAnswer>',
    qcmSelectedAnswers: 'java.util.List<fr.hiji.metier.mif.entities.eva.EVAQuizQCMAnswer>'
};
