// ENTITY fr.hiji.metier.mif.dto.back.event.stats.OGStatsPerFormationEventObservationTypeBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FormationEventObservationType } from './FormationEventObservationType';

export class OGStatsPerFormationEventObservationTypeBODTO {

  type: FormationEventObservationType | null = null;
  nb: number | null = 0;
  avg: number | null = null;

  constructor(init?:Partial<OGStatsPerFormationEventObservationTypeBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.event.stats.OGStatsPerFormationEventObservationTypeBODTO'] = OGStatsPerFormationEventObservationTypeBODTO;

reverseMapping[OGStatsPerFormationEventObservationTypeBODTO.name] = 'fr.hiji.metier.mif.dto.back.event.stats.OGStatsPerFormationEventObservationTypeBODTO';

fields['fr.hiji.metier.mif.dto.back.event.stats.OGStatsPerFormationEventObservationTypeBODTO']  = {
    type: 'fr.hiji.metier.mif.entities.event.observation.FormationEventObservationType',
    nb: 'java.lang.Integer',
    avg: 'java.lang.Double'
};
