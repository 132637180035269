// ENTITY fr.hiji.metier.campaign.entities.CampaignRepositoryPopulation generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:59 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class CampaignRepositoryPopulation {

  idCampaign: number | null = 0;
  idRepository: number | null = 0;
  idPopulation: number | null = 0;

  constructor(init?:Partial<CampaignRepositoryPopulation>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.entities.CampaignRepositoryPopulation'] = CampaignRepositoryPopulation;

reverseMapping[CampaignRepositoryPopulation.name] = 'fr.hiji.metier.campaign.entities.CampaignRepositoryPopulation';

fields['fr.hiji.metier.campaign.entities.CampaignRepositoryPopulation']  = {
    idCampaign: 'java.lang.Integer',
    idRepository: 'java.lang.Integer',
    idPopulation: 'java.lang.Integer'
};
