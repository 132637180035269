// ENTITY fr.hiji.metier.campaign.dto.back.CampaignRepositoryBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:58 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { CampaignRepositoryPopulationBODTO } from './CampaignRepositoryPopulationBODTO';

export class CampaignRepositoryBODTO {

  idRepository: number | null = 0;
  order: number | null = 0;
  del: boolean | null = false;
  name: MultilingualString | null = new MultilingualString();
  populations: CampaignRepositoryPopulationBODTO[] | null = [];

  constructor(init?:Partial<CampaignRepositoryBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back.CampaignRepositoryBODTO'] = CampaignRepositoryBODTO;

reverseMapping[CampaignRepositoryBODTO.name] = 'fr.hiji.metier.campaign.dto.back.CampaignRepositoryBODTO';

fields['fr.hiji.metier.campaign.dto.back.CampaignRepositoryBODTO']  = {
    idRepository: 'java.lang.Integer',
    order: 'java.lang.Integer',
    del: 'java.lang.Boolean',
    name: 'com.ic2.sc.MultilingualString',
    populations: 'java.util.List<fr.hiji.metier.campaign.dto.back.CampaignRepositoryPopulationBODTO>'
};
