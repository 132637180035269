// fr.hiji.metier.campaign.services.back.survey.SurveyBOServiceI generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:45:49 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';

@Injectable({
  providedIn: 'root',
})
export class SurveyBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  deleteSurveyUser(idCampaign: number, idUser: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SurveyBOService.deleteSurveyUser';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  resetToDraft(idCampaign: number, idUser: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SurveyBOService.resetToDraft';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
